.text {
  &-24 {
    font-family: AvertaPE;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }
  &-20 {
    font-family: AvertaPE;
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
  }

  &-18 {
    font-family: AvertaPE;
    color: var(--text);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-size: 18px;
  }
  &-16 {
    font-family: AvertaPE;
    color: var(--text);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-size: 16px;
  }

  &-14 {
    font-family: AvertaPE;
    color: var(--text);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-size: 14px;
  }
}

.text-707 {
  &-14 {
    font-family: AvertaPE;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }
}

.subtext {
  &-14 {
    font-family: AvertaPE;
    color: var(--subtext);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-size: 14px;
  }

  &-16 {
    font-family: AvertaPE;
    color: var(--subtext);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    font-size: 16px;
  }
}

.font-weight {
  &-600 {
    font-weight: 600;
  }

  &-300 {
    font-weight: 300;
  }
}

.opacity {
  &-8 {
    opacity: 0.8;
  }
  &-5 {
    opacity: 0.5;
  }
}

.text-error {
  color: var(--error);
  opacity: 1 !important;
}

.text-alert {
  color: var(--alert);
}

.text-border {
  color: var(--border);
}

.text-underline {
  text-decoration: underline;
}

.font-size-14 {
  font-size: 14px;
}
.font-size-13 {
  font-size: 13px;
}
