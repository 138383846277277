.section-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  .note {
    font-size: 14px;
    color: var(--text);
    .field-mandatory {
      color: var(--error);
    }
  }
}

.button {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  border-color: transparent;
  justify-content: center;
  align-items: center;

  &.btn-submit {
    background-color: var(--dark-blue);
    color: #fff;

    &:hover {
      background-color: var(--light-dark-blue);
    }

    .fa-spinner {
      padding: 0 5px;
    }
  }

  &.btn-cancel {
    background-color: transparent;
    border: 1px solid var(--border);
    color: var(--text);

    &:hover {
      background-color: #d8d8d8;
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #b1b5b8;
    color: #fff;
    &:hover {
      background-color: #b1b5b8;
    }
  }
}

$base-gap: 4px; // Base size for gaps
$max-gaps: 50; // Number of gap classes to generate

.gap {
  @for $i from 1 through $max-gaps {
    &-#{$i} {
      gap: $i * $base-gap;
    }
  }
}

.cursor-default {
  cursor: default;
}

.mat-radio-button.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--primary) !important;
}

.mat-radio-button .mat-radio-inner-circle {
  color: var(--primary) !important;
  background-color: var(--primary) !important;
}
