@import "app/assets/styles/custom-vendor";
@import "/styles/text.scss";
@import "/styles/colors.scss";
@import "/styles/inputs.scss";
@import "/styles/layout.scss";

@import "~bootstrap/scss/bootstrap";

@import "app/assets/styles/vertical-tabs";
@import "app/assets/styles/ngx-datatable";
@import "app/assets/styles/styles-vendor.css";
@import "fonts.scss";
@import "~@angular/material/theming";

@include mat-core();
$md-mcgpalette0: (
  50: #e4f3fb,
  100: #bce0f5,
  200: #90ccee,
  300: #64b7e7,
  400: #42a7e2,
  500: #2198dd,
  600: #1d90d9,
  700: #1885d4,
  800: #147bcf,
  900: #0b6ac7,
  A100: #f2f8ff,
  A200: #2198dd,
  A400: #8cc1ff,
  A700: #73b3ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$candy-app-primary: mat-palette($md-mcgpalette0);
$candy-app-accent: mat-palette($md-mcgpalette0, A200, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$candy-app-theme: mat-light-theme(
  (
    color: (
      primary: $candy-app-primary,
      accent: $candy-app-accent,
      warn: $candy-app-warn
    )
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($candy-app-theme);
$xpl-primary-color: #2a384b;
$secondary: #ff7f27;

:root {
  --primary: #2198dd;
  --primary8: rgba(33, 152, 221, 0.8);
  --primary6: rgba(33, 152, 221, 0.6);
  --text: #2b2b2b;
  --border: #a7b6bf;
  --border-storke: #e0e4e7;
  --border4: rgba(167, 182, 191, 0.4);
  --subtext: #474c4e;
  --subtext8: rgba(71, 76, 78, 0.8);
  --subtext6: rgba(71, 76, 78, 0.6);
  --white: #fff;
  --bg: rgba(42, 56, 75, 0.04);
  --success: #17bc53;
  --field-bg: rgba(42, 56, 75, 0.04);
  --secondary: #ff7f27;
  --error: #f00;
  --alert-bg: #fefbf0;
  --alert: #efbc0f;
  --error-bg: #fff4f4;
  --header-bg: #2a384b;
  --dark-blue: #2a384b;
  --light-dark-blue: #2a384ba2;
  --disable-bg: #e0e4e7;
  --inActive-tracking-line: #707070;
  --middleCircleTrackingPhase-futureActive: #c2e8fb;
  --outerCircleTrackingPhase-current: #c5e5f4;
  --packageFormBackground: rgba(237, 247, 255, 0.5);
  --shipmentUpdatesTracker: #fff8f4;
  --shipmentsTotalNumber: #246589;
  --dateOfFirstShipment: #c9cdd2;
  --companiesDropDownInNavBar: #efeff0;
  --disabled-check-box: #d9d9d9;
  --titleColor: #ff7f27;
  --dark-sky-blue: #2198dd;
  --sidenav-width: 247px;
  --sidenav-width: 247px;
  --sidenav-collapsed-width: 44px;
  --light-gray: #f5f5f5;
  --card-bg: rgba(42, 56, 75, 0.04);
  --card-disabled-bg: rgba(42, 56, 75, 0.04);
  --expire: #fe1414;
}

body,
html {
  height: 100%;
}

.reduced-font {
  font-size: 0.875rem;
}

.bg-primary,
.material .datatable-header {
  box-shadow: 0 3px 6px 0 rgba(49, 55, 58, 0.06) !important;
  background-color: $xpl-primary-color !important;
}

.bg-primary.form-control {
  color: white;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.max-height-250 {
  max-height: 250px !important;
}

.cursor-pointer {
  cursor: pointer;
}

input.form-control.left-status,
textarea.form-control.left-status,
.ng-select.form-control.left-status {
  border-left-width: 5px;
}

.ng-valid[required],
.ng-valid.required {
  border-left: 5px solid $form-feedback-valid-color;
}

// .ng-invalid:not(form) {
//   border-left: 5px solid $form-feedback-invalid-color;
// }

.is-valid {
  .ng-select.form-control {
    border-color: $form-feedback-valid-color;

    &.ng-select-focused {
      border-color: $form-feedback-valid-color;
      box-shadow: 0 0 0 $input-focus-width
        rgba($form-feedback-valid-color, 0.25);
    }

    padding-right: $input-height-inner;
    background-image: escape-svg($form-feedback-icon-valid);
    background-repeat: no-repeat;
    background-position: right $input-height-inner-quarter center;
    background-size: $input-height-inner-half $input-height-inner-half;
  }
}

.is-invalid {
  .ng-select.form-control {
    border-color: $form-feedback-invalid-color;

    &.ng-select-focused {
      border-color: $form-feedback-invalid-color;
      box-shadow: 0 0 0 $input-focus-width
        rgba($form-feedback-invalid-color, 0.25);
    }

    padding-right: $input-height-inner;
    background-image: escape-svg($form-feedback-icon-invalid);
    background-repeat: no-repeat;
    background-position: right $input-height-inner-quarter center;
    background-size: $input-height-inner-half $input-height-inner-half;
  }
}

.ng-select {
  &.form-control {
    padding: 0;
    height: unset !important;
    min-height: $input-height;

    .ng-select-container {
      border: none !important;
      box-shadow: none !important;
    }

    &.ng-select-focused {
      color: $input-focus-color;
      background-color: $input-focus-bg;
      border-color: $input-focus-border-color;
      outline: 0;
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows {
        box-shadow: $input-box-shadow, $input-focus-box-shadow;
      } @else {
        box-shadow: $input-focus-box-shadow;
      }
    }
  }
}

.ng-select {
  &.pill-items {
    .ng-value-container .ng-value {
      background-color: inherit !important;
    }

    .ng-value-icon.right {
      border-left: none !important;
      padding: 1px 0 1px 3px !important;
      font-weight: lighter;
    }

    .ng-value-icon:hover {
      background-color: inherit !important;
      font-weight: bold;
    }

    .ng-option {
      .badge-pill {
        max-width: 95%;
      }
    }

    .ng-option-selected {
      .fa.fa-check {
        position: absolute;
        right: 15px;
        display: inline-block !important;
      }
    }
  }

  &.theme-picker {
    .theme-item {
      text-align: center;
    }

    .ng-select-container .ng-value-container,
    .ng-select-container .ng-value-container .ng-value {
      width: 100%;
    }
  }
}

.dropdown-toggle.btn-light {
  background-color: #fff;
  border-color: #ccc;
}

.popover {
  padding: 18px;
  max-width: none !important;
  border-radius: 10px;
  border: none;
  box-shadow: 0 3px 20px 0 rgba(49, 55, 58, 0.15);
}

.app-component .navbar .popover {
  min-width: 500px;
}

.shipment-details-page .page-title .popover {
  min-width: 330px;
}

.modal.login-control {
  .modal-content {
    border: none;
    background-color: transparent;

    .modal-body {
      padding: 0;
    }
  }
}

.disabledContainer {
  pointer-events: none;
  opacity: 0.4;
}

.pageHeader {
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
}

i.page-caption {
  vertical-align: baseline;
  font-size: 1.2em;
}

.alertify {
  top: 75px;
}

.alertify-message {
  font-size: 1.1rem;
}

#toasta.toasta-position-top-right {
  top: 20px;
  right: 20px;
}

#toasta .toast.toasta-theme-bootstrap {
  font-size: 0.9em;
}

#toasta .toast.toasta-theme-bootstrap .toast-text .toast-title {
  font-size: 1.02em;
}

#toasta .toast-text .toast-msg {
  max-width: 1000px;
  max-height: 800px;
  display: inline-block;
  overflow: auto;
}

///*-webkit-filter: blur(5px) grayscale(90%);*/
///*body.modal-open .container {
//    -webkit-filter: blur(1px);
//    -moz-filter: blur(1px);
//    -o-filter: blur(1px);
//    -ms-filter: blur(1px);
//    filter: blur(1px);
//    filter: url("https://gist.githubusercontent.com/amitabhaghosh197/b7865b409e835b5a43b5/raw/1a255b551091924971e7dee8935fd38a7fdf7311/blur".svg#blur);
//    filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='1');
//}*/
.vertical-center-flex {
  min-height: 80vh;
  display: flex;
  align-items: center;
}

///*Hack stop get ngx-datatable width to be full when inside bootstrap tab control*/
//.tab-pane .datatable-scroll {
//    width: 100% !important;
//}
.ngx-datatable.scroll-vertical {
  height: 64vh;
}

.black-anchor {
  color: #333;
}

.form-group .icon-addon .form-control {
  border-radius: 25px;
}

.icon-addon {
  position: relative;
  color: #555;
  display: block;
}

.icon-addon:after,
.icon-addon:before {
  display: table;
  content: " ";
}

.icon-addon:after {
  clear: both;
}

.icon-addon.addon-md .fa,
.icon-addon .fa {
  position: absolute;
  z-index: 2;
  font-size: 1rem;
  width: 20px;
  text-align: center;
  padding: 10px 0;
  top: 1px;
}

.icon-addon .fa.left-icon {
  left: 10px;
  margin-left: -2.5px;
}

.icon-addon .fa.right-icon {
  right: 10px;
  margin-right: -2.5px;
}

.icon-addon.addon-lg .form-control {
  line-height: 1.33;
  height: 46px;
  font-size: 18px;
}

.icon-addon.addon-lg .form-control.left-icon {
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-left: 40px !important;
}

.icon-addon.addon-lg .form-control.right-icon {
  padding-top: 10px;
  padding-right: 40px !important;
  padding-bottom: 10px;
  padding-left: 16px;
}

.icon-addon.addon-sm .form-control {
  height: 30px;
  font-size: 0.875rem;
  line-height: 1.5;
}

.icon-addon.addon-sm .form-control.left-icon {
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 28px !important;
}

.icon-addon.addon-sm .form-control.right-icon {
  padding-top: 5px;
  padding-right: 28px !important;
  padding-bottom: 5px;
  padding-left: 10px;
}

.icon-addon.addon-lg .fa {
  font-size: 18px;
  top: 4px;
}

.icon-addon.addon-lg .fa.left-icon {
  margin-left: 0;
  left: 11px;
}

.icon-addon.addon-lg .fa.right-icon {
  margin-right: 0;
  right: 11px;
}

.icon-addon.addon-md .form-control,
.icon-addon .form-control {
  font-weight: normal;
}

.icon-addon.addon-md .form-control.left-icon,
.icon-addon .form-control.left-icon {
  float: left;
  padding-left: 30px;
}

.icon-addon.addon-md .form-control.right-icon,
.icon-addon .form-control.right-icon {
  float: right;
  padding-right: 30px;
}

.icon-addon.addon-sm .fa {
  font-size: 12px;
  top: -1px;
}

.icon-addon.addon-sm .fa.left-icon,
.icon-addon.addon-sm .glyphicon.left-icon {
  margin-left: 0;
  left: 5px;
}

.icon-addon.addon-sm .fa.right-icon,
.icon-addon.addon-sm .glyphicon.right-icon {
  margin-right: 0;
  right: 5px;
}

.icon-addon .form-control:focus + .glyphicon,
.icon-addon:hover .glyphicon,
.icon-addon .form-control:focus + .fa,
.icon-addon:hover .fa {
  color: #2580db;
}

.clear-input {
  text-decoration: none !important;
  color: #b3b3b3 !important;
}

.clear-input:hover {
  color: #2580db !important;
}

.no-data {
  text-align: center;
  border: #f9f9f9 2px dotted;
}

.actions-bar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px !important;
}

table {
  width: 100%;
}

.separator-hr {
  margin: 7px 5px;
  border-top-style: dashed;
}

.vertical-separator {
  border-left: 1px solid #eaecee;
  padding: 0px 10px;
  border-left-style: dashed;
}

.small-tip {
  font-size: 85%;
  font-style: italic;
}

.bread-crumb-nav {
  font-size: 120%;
}

.secondary {
  color: $secondary;
}

.font-size-150-percent {
  font-size: 150%;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: AvertaPE, "Helvetica Neue", sans-serif;
}

.mat-expansion-panel-body {
  padding: 0px !important;
}

.older-version-not-expanding {
  overflow: visible !important;

  .mat-expansion-panel-content {
    visibility: unset !important;
    display: none;
  }
}

.mat-content {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
}

.no-service-selected .mat-radio-outer-circle,
app-totals-detailed-packages-information-selection
  .validation-error
  .mat-radio-outer-circle,
app-package-details .validation-error .mat-radio-outer-circle {
  border-color: var(--error) !important;
}

.mat-radio-button .mat-radio-ripple .mat-ripple-element {
  background-color: rgb(255, 37, 37, 0.26) !important;
}

.mat-content {
  position: relative;
}

.pull-right {
  position: absolute;
  right: 15px;
}

.form-aqua-container {
  background-color: var(--bg);
  border-radius: 10px;
}

.field-bg {
  background-color: var(--field-bg) !important;
}

.section-bg {
  background-color: rgb(237, 247, 255);
}

.disabled-field-bg {
  background-color: var(--disable-bg) !important;
}

app-choose-services .popover,
app-choose-services-version2 .popover {
  min-width: 13rem;
}

.ng-select-container {
  background-color: var(--field-bg) !important;
  padding: 10px;
  border: 1px solid var(--border-storke) !important;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  color: var(--primary) !important;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: var(--disable-bg) !important;
}

.ng-select.ng-select-disabled .ng-value-label {
  color: var(--subtext) !important;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  height: 8px !important;
  width: 8px !important;
}

.ng-select.ng-select-clearable
  .ng-select-container.ng-has-value:hover
  .ng-arrow-wrapper {
  opacity: 1 !important;
  pointer-events: none;
}

.mat-checkbox-background,
.mat-checkbox-frame {
  border-radius: 4px !important;
}

.mat-checkbox-frame {
  border: 1px solid #a8b7c0 !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: var(--field-bg) !important;
}

.mat-dialog-container {
  border-radius: 13px !important;
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: var(--disable-bg) !important;
}

button.back {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(--primary);
  margin: 0 0 30px 0;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.81;
  letter-spacing: normal;
  text-align: left;
  padding: 0;
  text-decoration: none !important;

  svg {
    margin-right: 15px;
    display: inline-block;
  }
}

.m-3-percent {
  margin: 3%;
}

.success-snack-bar-container {
  background-color: var(--white);
  color: var(--text);
  position: absolute;
  top: 100px;
  right: 0px;
  border-radius: 8px 0px 0px 8px !important;
  box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--success);
  min-width: 250px !important;
  margin-right: 0 !important;
}

.underlined {
  text-decoration: underline;
}

.mat-checkbox-inner-container {
  margin-top: 2px !important;
  width: 20px !important;
  height: 20px !important;
}

.mat-tab-label,
.mat-tab-label-active {
  min-width: 20% !important;
  padding: 3px !important;
  margin: 3px !important;
  font-family: AvertaPE !important;
  font-size: 16px !important;
}

.mat-tab-label {
  color: var(--text) !important;
  font-weight: normal !important;
}

.mat-tab-label-active {
  color: var(--primary) !important;
  opacity: 1 !important;
  font-weight: 600;
}

.mat-tab-label.cdk-focused,
.mat-tab-label.cdk-mouse-foucused {
  color: var(--primary) !important;
}

.mat-ink-bar {
  background: linear-gradient(
    to right,
    white 41%,
    var(--primary) 41%,
    var(--primary) 59%,
    white 59%,
    white 100%
  );
}

.mat-tab-header {
  border-bottom: 1px solid rgba(167, 182, 191, 0.4) !important;
}

.primary-link {
  font-size: 16px;
  color: var(--primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.81;
  letter-spacing: normal;
}

.rotate-180-y {
  transform: rotateY(180deg) !important;
  display: inline-block;
}

.cdk-overlay-container {
  z-index: 999999;
}

.error-bar .mat-progress-bar-buffer {
  background-color: var(--error) !important;
}

.error-bar .mat-progress-bar-fill::after {
  background-color: var(--error) !important;
}

app-shipment-details-document .popover {
  border-radius: 10px;
  border: none;
  box-shadow: 0 3px 20px 0 rgba(49, 55, 58, 0.15);
  min-width: 331px;
  max-width: 51% !important;
  padding: 17px 13px;
  background-color: var(--white);
  margin-left: 3px;
}

.older-version-list-header .mat-content {
  overflow: visible !important;
}

.currency-drop-down .ng-select-container {
  border-radius: 8px !important;
  background-color: var(--field-bg) !important;
  height: 40px;
}

.error-app-qunatity-input .quantity-input-con {
  border: 1px solid var(--error) !important;
  background-color: var(--field-bg) !important;

  input {
    background-color: var(--field-bg) !important;
  }
}

.charge-quantity-input .quantity-input-con,
.charge-quantity-input .quantity-input-con input {
  background-color: var(--field-bg) !important;
  border: none !important;
}

.noElementsExistsCon {
  display: grid;
  padding: 5% 0;

  & svg-icon {
    margin-left: auto;
    margin-right: auto;
  }

  & span {
    opacity: 0.6;
    font-size: 16px;
    color: var(--text);
    margin-left: auto;
    margin-right: auto;
  }
}

.input-filed-placeholder::placeholder {
  font-size: 14px;
  color: rgba(71, 76, 78, 0.5);
}

.add-service-charge-con.ng-invalid:not(form),
.add-service-charge-con .ng-invalid:not(form) {
  border: none;
}

.older-version-list {
  border-bottom: 1px solid var(--border);
  border-radius: 0px !important;
}

.deletion-reason-con {
  font-size: 14px;
  line-height: 1.43;
  padding: 2px;
  color: var(--subtext);

  .reason-title {
    grid-column: 1;
    font-weight: 300;
  }

  .reason-info {
    grid-column: 2 / span 3;
  }

  .deletion-reason {
    font-style: italic;
  }
}

.mat-tab-body-content {
  overflow: visible !important;
  min-height: 400px;
}

.mat-tab-body.mat-tab-body-active:last-of-type .mat-tab-body-content {
  overflow: hidden !important;
}

.mat-paginator {
  background-color: transparent !important;
}

.mat-paginator-container {
  min-height: 30px !important;
  justify-content: space-between !important;
}

.mat-paginator-outer-container {
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.mat-paginator-page-size-value {
  display: none;
}

app-add-trucking-details {
  .ng-select .ng-select-container {
    background-color: white !important;
    height: 40px !important;

    .ng-arrow-wrapper {
      display: table;
    }
  }

  app-quantity-input,
  app-input {
    .default-border {
      width: 164px !important;
      border-radius: 5px !important;
    }
  }
}

app-selected-shipment-heading button.back {
  font-size: 12px;
  margin-bottom: 15px;
  line-height: 16px;

  svg {
    margin-right: 5px;
  }

  svg-icon {
    height: 12px;
    margin-top: -5px !important;
  }
}

app-dashboard-active-shipments {
  app-page-header .page-header span {
    font-size: 18px !important;
  }
}

.primary-button {
  border-radius: 10px;
  background-color: var(--primary);
  border-color: transparent;
  color: var(--white);
  padding: 2px 8px;
  font-weight: 600;
  font-family: AvertaPE, "Helvetica Neue", sans-serif;
}

app-shipments-total-number {
  .popover {
    padding: 10px 8px;
    text-align: center;

    .arrow::before,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      border-bottom-color: white;
      border-width: 0 0.7rem 0.5rem 0.7rem !important;
    }

    .arrow::after,
    .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      border-width: 0 0.7rem 0.5rem 0.7rem !important;
    }
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 28px;
  }

  .mat-button-toggle-checked {
    background-color: var(--primary) !important;
    color: white !important;
    font-weight: 600;
  }

  .mat-button-toggle-group-appearance-standard {
    height: 28px;
    margin-block: auto;
    border: none;
    border-radius: 7px;
    background-color: rgba(33, 152, 221, 0.1);
  }

  .mat-button-toggle-appearance-standard {
    border-radius: 7px;
    background-color: transparent;
    color: #7f8893;
    font-size: 12px;
  }

  .mat-button-toggle-group-appearance-standard
    .mat-button-toggle
    + .mat-button-toggle {
    border-left: none;
  }

  .mat-button-toggle-focus-overlay {
    display: none;
  }
}

app-upload-document {
  .doument-other-name {
    &:focus-visible {
      outline: none !important;
      border: 1px solid #3aa3e1 !important;
    }

    outline: none !important;
    border: 0px !important;
  }

  .ng-invalid {
    border: 1px red solid !important;
    border-radius: 5px;
  }

  .supplier-document-date {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        width: 272px !important;
        height: 40px !important;
        border-radius: 5px !important;
        border: solid 1px #e7e7e7 !important;
        background-color: #f5f5f5 !important;
        padding-left: 7px !important;
        color: var(--subtext);
        padding-right: 0 !important;

        .mat-form-field-infix {
          top: -5px;
        }

        .mat-form-field-suffix {
          top: -7px;
        }
      }
    }
  }

  .date-custom {
    .custom-datepicker {
      .mat-form-field-flex {
        height: 48px;
      }

      .mat-form-field-wrapper {
        padding-bottom: 0px;
      }

      .mat-form-field-infix {
        border-top: 0;
      }

      .mat-form-field-appearance-fill .mat-form-field-flex {
        padding: 0;
      }

      .mat-form-field-underline {
        display: none;
      }
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 0px;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    width: 355px;
    height: 50px;
    padding: 0px 25px;
    border-radius: 5px;
  }

  .mat-form-field-underline {
    display: none;
  }
}

app-upload-docs {
  .ng-invalid {
    border: 1px red solid !important;
    border-radius: 5px;
    outline: none;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding: 15px 12px;
  }

  .popover-body {
    width: 350px;
  }
}

app-dashboard-map .gm-style .gm-style-iw-c {
  padding: 5px 14.76px 2px 14.76px !important;
  box-shadow: 0 3px 20px 0 rgba(49, 55, 58, 0.15);

  .gm-style-iw-chr {
    height: 1vh;
  }
}

app-dashboard-map .gm-style-iw-d {
  overflow: unset !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}

app-dashboard-map button.gm-ui-hover-effect {
  visibility: hidden;
}

app-shipments-list-table-view,
app-product-registration-table-view,
app-quote-table-view,
app-product-table-view,
app-table-view {
  tbody tr {
    height: 53px !important;
  }

  .mat-checkbox-layout {
    margin-bottom: 0px !important;

    .mat-checkbox-inner-container {
      width: 18px !important;
      height: 18px !important;
    }
  }

  mat-checkbox.mat-checkbox-disabled {
    .mat-checkbox-frame {
      background-color: var(--disable-bg);
    }
  }

  app-checkbox {
    .checkmark {
      width: 18px !important;
      height: 18px !important;
    }
  }
}

app-dashboard-map .gmnoprint:last-of-type div:first-child {
  background-color: #e9f5fc !important;
  border-radius: 5px !important;
}

app-dashboard-active-shipments .Subtraction {
  height: 10px !important;
}

app-sort-filter input[type="radio"] {
  border: 0px;
  width: 18px;
  height: 2em;
}

app-shipments-list-filters,
app-bulk-action,
app-list-filters {
  .mat-expansion-panel
    .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled="true"]),
  .mat-expansion-panel
    .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled="true"]),
  .mat-expansion-panel:not(.mat-expanded)
    .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
    background: none !important;
  }

  .mat-expansion-panel-header:focus-visible:not([aria-disabled="true"]) {
    outline: none !important;
  }

  .mat-expansion-indicator {
    padding: 0 25px !important;

    &::after {
      color: var(--primary) !important;
    }
  }
}
app-shipments-list-filters {
  .date-range-panel {
    .custom-datepicker {
      width: 100% !important;
    }

    .mat-expansion-panel-content {
      background-color: #f5fafd;
      display: flex;
      justify-content: center;
      align-items: center;

      .mat-expansion-panel-body {
        width: 100%;
        overflow: hidden;
      }
    }

    .ng-select {
      .ng-dropdown-panel {
        position: relative;

        .ng-option .ng-star-inserted {
          display: flex;
        }

        .scroll-host {
          box-shadow: 0 0 0 1px #d9dfe2;
          border-radius: 0px 0px 6px 6px;
        }

        .ng-option {
          padding: 12px !important;
          color: #a3a5a6 !important;

          .circle {
            outline-width: 1px;
            outline-color: #bebebe;
          }
        }

        .ng-option-selected {
          font-weight: 300 !important;

          .circle {
            background-color: var(--dark-sky-blue) !important;
            border: solid 3px #fff !important;
            outline-color: var(--dark-sky-blue);
          }
        }
      }

      .ng-select-container {
        background-color: #ffffff !important;
        border-color: transparent !important;
      }

      .ng-select.ng-select-opened > .ng-select-container {
        box-shadow: 0 0 0 1px #d9dfe2;
      }
    }

    .mat-datepicker-content .mat-calendar .mat-calendar-body-selected {
      font-size: 14px;
    }

    .mat-form-field-appearance-fill {
      .mat-form-field-flex {
        align-items: center;
        justify-content: center;
        padding: 8px 12px !important;
        background-color: #fff !important;
        border: unset !important;
      }

      .mat-form-field-underline {
        display: none !important;
      }

      .mat-form-field-wrapper {
        padding-bottom: 0 !important;
      }

      .mat-form-field-infix {
        padding: 0 !important;
        border-top: unset !important;
        width: 50px;
        padding: unset !important;
      }
    }
  }
}

.custom-filter-modal {
  mat-dialog-container {
    padding: 0;
  }
}

mat-tab-group {
  width: 100%;

  mat-tab-body {
    margin-top: 40px;
  }
}

.custom-select-freight-option-dialog {
  mat-dialog-container {
    padding: 50px;
  }
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(167, 182, 191, 0.4) !important;
  border-radius: 4px !important;
}

app-notifications-viewer {
  *::-webkit-scrollbar-thumb {
    background-color: white !important;
  }

  *::-webkit-scrollbar {
    background-color: rgba(167, 182, 191, 0.4) !important;
    border-radius: 4px !important;
  }
}

*::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

.mat-menu-panel {
  max-width: 350px !important;
}

app-shipment-invoice-details {
  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0 0 0 7px;
    width: 160px !important;
  }

  .mat-content {
    display: contents !important;
  }

  .mat-expansion-panel-header {
    display: block !important;
  }

  .mat-expansion-panel {
    overflow: visible !important;
  }

  .fa-minus,
  .fa-plus {
    color: var(--primary) !important;
  }

  .mat-form-field-flex,
  .ng-select-container {
    width: 143px !important;
  }

  .ng-invalid {
    border-left: 0px !important;
  }

  .customDatepicker {
    .mat-form-field-flex {
      padding: 0.3rem 0.5rem;
      height: 48px;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      border-top: 0;
    }

    .mat-form-field-appearance-fill .mat-form-field-flex {
      padding: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }

  .customtoday {
    .mat-calendar-body-today {
      border: none !important;
      position: relative;

      &:after {
        content: "";
        bottom: 0;
        width: 100%;
        position: absolute;
        height: 2px;
      }
    }
  }

  .ng-select-container {
    height: 48px !important;
    width: 160px !important;
  }

  .ng-dropdown-panel {
    width: 160px;
  }
}

app-shipments-lists,
app-product-registration-list,
app-quote-list-tabs,
app-product-library-list,
app-warehousing-list {
  .mat-paginator-page-size-label {
    margin: 0px 681px 0px 47px !important;
    font-family: AvertaPE !important;
    font-size: 12px !important;
    font-style: normal !important;
    color: var(--subtext);
  }

  .mat-paginator-range-label {
    margin: 0 -268px 0 23px;
    font-family: AvertaPE;
    font-size: 12px;
    color: var(--text);
  }

  .count-only .mat-paginator-range-actions {
    display: none;
  }

  .mat-icon-button {
    padding: 0 59px 0px 129px;
  }

  .mat-paginator-range-actions {
    margin-right: 22px;
  }

  .mat-paginator-icon {
    width: 22px;
  }

  .mat-paginator-container {
    padding-left: 0px !important;
    padding-right: 0px !important;

    .mat-paginator-page-size {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }
}

.save-button {
  background-color: var(--success) !important;
  color: white !important;
  border: 1px solid white !important;

  &:disabled {
    opacity: 0.5;
  }
}

.delete-draft-button {
  padding: 15px 60.8px 14px 60px !important;
  background: none !important;
  color: red !important;
  border: 1px solid red !important;

  &:disabled {
    opacity: 0.5;
  }
}

app-post-shipment-activity {
  .ng-select .ng-select-container .ng-value-container .ng-input > input {
    padding: 8px 3px !important;
  }
}

.Invalid,
.Expired {
  color: #fff;
  background-color: #dc3545;
}

app-dangerous-material {
  .shipment-products-class-field {
    .ng-select,
    .ng-select div,
    .ng-select input,
    .ng-select span {
      display: flex;
      flex-wrap: wrap;
    }

    .ng-dropdown-panel {
      box-shadow: 0 0 0 1px #40a9ff;
      margin-top: 2px;
      margin-bottom: 2px;
    }

    .ng-dropdown-panel
      .ng-dropdown-panel-items
      .ng-option.ng-option-selected.ng-option-marked {
      color: rgba(0, 0, 0, 0.65);
      background-color: #e6f7ff;
      width: 195;
    }

    .ng-dropdown-panel .scroll-host {
      padding: 25px;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
      background-color: white;
    }

    .ng-dropdown-panel
      .ng-dropdown-panel-items
      .ng-option.ng-option-selected.ng-option-marked {
      width: 200;
      background-color: white;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
      width: 200;
      padding: 5px 6px;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
      background-color: white;
    }

    .ng-select .ng-clear-wrapper {
      display: none;
    }

    .ng-select.ng-select-multiple
      .ng-select-container
      .ng-value-container
      .ng-value
      .ng-value-icon {
      color: var(--error);
      font-size: 23px;
      margin-top: -1px;
    }

    .ng-select.ng-select-multiple
      .ng-select-container
      .ng-value-container
      .ng-value {
      flex-direction: row-reverse;
      background-color: #c4e2f9;
      border-radius: 5px;
      font-family: AvertaPE;
      font-size: 14px;
    }

    .ng-select.ng-select-multiple
      .ng-select-container
      .ng-value-container
      .ng-value
      .ng-value-label {
      padding: 0px 2px 0 5px;
      font-size: 12px;
      font-family: AvertaPE;
      font-weight: normal;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
      padding-left: 15px;
      padding-right: 15px;
    }

    .ng-select.ng-select-opened > .ng-select-container {
      box-shadow: 0 0 0 1px #40a9ff;
    }
  }

  app-box-width-triangle {
    .box-wrapper {
      .Union-4 {
        min-height: 325px;
        height: fit-content;
      }
    }

    &:before,
    &:after {
      display: none;
    }
  }
}

app-shipment-custom-control {
  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0 0 0 7px;
  }

  .mat-content {
    display: contents !important;
  }

  .mat-expansion-panel-header {
    display: block !important;
  }

  .mat-expansion-panel {
    overflow: visible !important;
    max-width: 983px;
  }

  .customDatepicker {
    .mat-form-field-flex {
      padding: 0.3rem 0.5rem;
      height: 48px;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      border-top: 0;
    }

    .mat-form-field-appearance-fill .mat-form-field-flex {
      padding: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }

  .customtoday {
    .mat-calendar-body-today {
      border: none !important;
      position: relative;

      &:after {
        content: "";
        bottom: 0;
        width: 100%;
        position: absolute;
        height: 2px;
      }
    }
  }
}

.paginator-bar {
  width: 100%;
  height: 36px;
  border-radius: 5px;
  background-color: var(--field-bg);
}

app-report-search-bar {
  width: 100%;
}

app-shipment-products {
  .hs-code-field
    .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value {
    border: none !important;
    margin-bottom: 9px;
  }

  .hs-code-field .ng-select .ng-select-container {
    max-height: 44px;
    overflow-y: auto;
    padding-left: 22px;
    padding-top: 11px;
  }

  .two-hs-codes .ng-select .ng-select-container {
    overflow-y: hidden !important;
  }

  .hs-code-field
    .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container {
    max-height: 53px;
  }

  .hs-code-field .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    color: var(--inActive-tracking-line) !important;
    margin: 15px 0px;
    padding-left: 21px;
  }
}

app-comment-pending-action-fulfill-information {
  .popover {
    min-width: 291px !important;
    padding: 25px;
  }

  .popover-body {
    padding: 0 0 0 0.7rem;
  }
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 5px !important;
}

app-report-content {
  .ngx-datatable .datatable-header {
    text-align: center;
  }

  .ngx-datatable.fixed-row
    .datatable-scroll
    .datatable-body-row
    .datatable-body-cell,
  .ngx-datatable.fixed-row
    .datatable-scroll
    .datatable-body-row
    .datatable-body-group-cell {
    justify-content: center;
  }
}

app-lcl-container-details {
  .ng-select .ng-arrow-wrapper .ng-arrow {
    display: block;
  }

  .ng-invalid {
    border: 0;
  }

  .ng-select.ng-select-opened > .ng-select-container,
  .ng-dropdown-panel.ng-select-bottom {
    position: inherit !important;
  }

  .ng-dropdown-panel .scroll-host {
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  }

  .ng-select.ng-select-disabled .ng-arrow-wrapper {
    padding-top: 7px;
  }
}

app-container-details-numbers {
  .ng-invalid {
    border: 0px;
  }

  .error-badge-thin {
    width: 100% !important;
  }

  .warning-badge div:first-child {
    margin-right: 9px;
    border-radius: 8px !important;
    height: 41px;
  }

  .error-message {
    padding-top: 5px;
  }
}

.no-service-selected .mat-radio-outer-circle,
app-cargo-handling .validation-error .mat-radio-outer-circle {
  border-color: var(--error) !important;
}

app-dashboard-selected-shipment {
  .pending-actions-container {
    padding: 10px 40px 22px 23px !important;
  }
}

app-lead-times {
  .popover-body {
    max-width: 225px;
  }
}

app-choose-services,
app-choose-services-version2 {
  radio-button-card.disable div div.circlCon .circle {
    background-color: var(--disable-bg) !important;
  }

  radio-button-card
    .hasDomesticOption
    .radio-button-card.account-management-card
    .container
    div {
    div.service-selection-font {
      padding-left: 48px;
      padding-right: 0px;
    }
  }

  .radio-button-error
    .mat-radio-label
    .mat-radio-container
    .mat-radio-outer-circle {
    border: 1px solid var(--error) !important;
  }
}

app-shipment-routing {
  .involved-parties {
    .ng-dropdown-panel {
      .ng-option {
        padding-top: 6px !important;
        height: 34px;
      }
    }
  }

  .ng-dropdown-panel.ng-select-top,
  .ng-dropdown-panel.ng-select-bottom {
    display: flex;
    flex-direction: column-reverse;
  }

  .ng-dropdown-panel .ng-dropdown-footer {
    padding: 0.7rem 1.5rem 0rem 0.7rem !important;
    background-color: var(--field-bg);
    color: var(--primary);

    p {
      margin-bottom: 0.4rem;
    }
  }

  .ng-dropdown-panel .scroll-host {
    margin-right: -1.5px;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .mat-form-field {
    .mat-form-field-wrapper {
      .mat-form-field-flex .mat-form-field-infix .mat-input-element {
        margin-bottom: 2px !important;
      }

      .mat-form-field-suffix
        .mat-datepicker-toggle
        .mat-button-wrapper
        .mat-icon {
        margin-bottom: 12px !important;
      }
    }
  }
}

app-cargo-details,
app-quote-cargo-details {
  .trucking-domestic-title {
    app-section-with-header {
      .accordionCon {
        padding-top: 0px !important;

        .titleCon {
          padding-bottom: 26px !important;
        }
      }
    }
  }

  .domestic-trucking {
    app-section-with-header {
      .accordionCon {
        .body {
          padding-top: 26px !important;
          padding-bottom: 26px !important;
        }
      }
    }
  }

  .domestic-trucking-container {
    app-cargo-handling {
      .cargo-handling-container {
        margin-bottom: 30px !important;
      }
    }

    app-totals-package-form {
      .totals-package-form-container {
        padding-left: 0px !important;
        padding-bottom: 30px !important;
        padding-top: 0px !important;
        margin-top: -20px !important;
      }

      app-input-number {
        .default-border {
          height: 40px;
          width: 128px !important;
          border-radius: 5px !important;
        }
      }
    }
  }

  app-add-domestic-trucking-information {
    app-quantity-input {
      .quantity-input-con {
        width: 162px !important;
        height: 40px !important;
      }
    }

    .ng-select .ng-select-container .ng-value-container .ng-input > input {
      padding: 10px;
    }

    .ng-select .ng-clear-wrapper {
      display: none;
    }
  }

  .custom-ng-dropdown {
    .ng-dropdown-panel .scroll-host {
      margin-right: 18.5px;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  .cargo-type-section {
    .ng-select .ng-select-container {
      background-color: white !important;
    }
  }

  app-package-form,
  app-container-details-form {
    app-quantity-input {
      .disabled-quantity,
      .disabled-input div {
        background-color: var(--disable-bg) !important;

        input:disabled,
        &.disabled {
          background-color: var(--disable-bg);
        }
      }
    }
  }

  app-totals-package-form,
  app-package-form {
    app-input-number {
      .default-border {
        height: 40px;
        width: 128px !important;
        border-radius: 5px !important;
      }
    }

    app-label-with-input {
      .label {
        margin-left: -0.5px !important;
      }
    }

    app-quantity-input {
      .default-border {
        height: 40px !important;
        border-radius: 5px !important;
      }
    }
  }

  app-chargeable-weight-form {
    .chargeable-weight-form-container {
      padding-left: 0px !important;
      padding-bottom: 30px !important;
      padding-top: 0px !important;
      margin-top: -20px !important;

      app-label-with-input {
        .label {
          margin-left: -0.5px !important;
        }
      }

      app-input-number {
        .default-border {
          height: 40px;
          width: 272px !important;
          border-radius: 5px !important;
        }
      }
    }
  }

  .ng-select.ng-select-disabled > .ng-select-container {
    background-color: var(--disable-bg) !important;
  }

  .disabled-checkbox {
    .mat-checkbox-layout {
      .mat-checkbox-inner-container {
        cursor: default;

        .mat-checkbox-background {
          background-color: var(--disable-bg);
        }
      }
    }
  }
}

app-add-or-edit-commercial-transaction-party {
  .error-badge-thin {
    width: inherit !important;
    margin-left: 11px;
    margin-right: 11px;
    border-radius: 15px !important;
  }
}

.disabled-input div {
  background-color: var(--disable-bg) !important;
}

.validation-error div {
  border-color: red !important;
}

app-involved-party {
  .ng-option-selected {
    display: flex;

    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: var(--primary) !important;

      &:after {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--primary);
      }
    }
  }

  .ng-select.ng-select-clearable
    .ng-select-container.ng-has-value:hover
    .ng-clear-wrapper {
    opacity: 0;
  }
}

app-package-details {
  .package-details-container.ng-invalid,
  mat-radio-group.ng-invalid,
  .total-package-form-container.ng-invalid,
  app-input-number.ng-invalid,
  app-input.ng-invalid,
  .package-details-form.ng-invalid,
  .package-details-row.ng-invalid,
  app-quantity-input.ng-invalid {
    border-left: none !important;
  }

  app-quantity-input .quantity-input-con.validation-error {
    border-color: red !important;
  }

  &.package-details-container .section-subtitle {
    padding-left: 15px !important;
  }

  app-input .default-border,
  app-quantity-input .default-border {
    border: solid 1px var(--border) !important;
    height: 40px;
  }
}

app-container-details-1 {
  .container-details.ng-invalid,
  app-input-number.ng-invalid,
  app-input.ng-invalid,
  .container-details-form.ng-invalid,
  .container-details-row.ng-invalid,
  app-quantity-input.ng-invalid {
    border-left: none !important;
  }

  app-quantity-input .quantity-input-con.validation-error {
    border-color: red !important;
  }

  app-input .default-border,
  app-quantity-input .default-border {
    border: solid 1px var(--border) !important;
    height: 40px;
  }
}

app-quote-cargo-details {
  radio-button-card {
    .radio-button-card {
      padding-left: 25px !important;
    }
  }

  app-quantity-input .default-border {
    border: solid 1px var(--border) !important;
  }

  .breakbulk-package app-input .default-border,
  .breakbulk-package app-quantity-input .default-border {
    height: 40px;
  }

  app-quantity-input .validation-error {
    border-color: var(--error) !important;
  }
}

app-cargo-products {
  .countries-ddl {
    .ng-dropdown-panel .ng-dropdown-panel-items {
      border: 1px solid var(--primary) !important;
      margin-top: -4px !important;
    }
  }

  .ng-option-selected {
    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: var(--primary) !important;

      &:after {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--primary);
      }
    }
  }

  .ddl-border {
    .ng-dropdown-panel .ng-dropdown-panel-items {
      border: 1px solid var(--primary) !important;
      margin-top: -4px !important;
    }
  }

  app-input .default-border {
    border-radius: 5px !important;
  }

  .other-input .default-border {
    height: 41px !important;
    width: 227px !important;
  }

  .select-wrapper {
    .ng-select-container {
      background-color: var(--field-bg) !important;
      height: 48px;
      border-radius: 5px !important;
      border: none !important;
    }
  }

  .hs-code-field
    .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value {
    border: none !important;
  }

  .hs-code-field .ng-select .ng-select-container {
    max-height: 48px;
    overflow-y: auto !important;
    padding-left: 22px;
    padding-top: 11px;
  }

  .hs-code-field .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    color: var(--inActive-tracking-line) !important;
    margin: 15px 0px !important;
    padding-left: 21px !important;
  }

  .select-wrapper {
    .ng-select-container {
      background-color: var(--field-bg) !important;
      height: 48px;
      border-radius: 5px !important;
      border: none !important;
    }

    :host .ng-select-opened {
      border: 1px var(--primary) solid;
      border-radius: 5px;

      .ng-arrow {
        transform: rotate(-90deg) !important;
      }
    }

    :host .ng-option {
      font-size: 14px;
      color: #707070;
    }

    :host .ng-dropdown-panel {
      border: 1px var(--primary) solid !important;
    }

    :host .validation-err {
      border: 1px red solid !important;
      border-radius: 5px;
      height: 48px !important;
    }

    :host .ng-select .ng-select-container {
      height: 100% !important;
      background-color: var(--field-bg);
      border-radius: 5px;
      border: 0 !important;
      padding: 6px 9px;

      .ng-value {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        border: none;

        .ng-value-icon {
          color: var(--error);
          font-size: 20px;
          border: none !important;
          border-radius: 0 !important;
        }

        .ng-value-label {
          font-size: 14px;
          line-height: 1.43;
          color: var(--subtext);
        }
      }

      .ng-arrow-wrapper {
        display: table;
        height: 100%;

        .ng-arrow {
          border-color: inherit !important;
          border-width: 0 !important;
          font-size: 20px;
          display: table-cell;
          text-align: center;
          color: inherit;
          text-align: center;
          transform: rotate(90deg);
          color: var(--primary) !important;
          transition: transform 0.2s ease;

          &:before {
            content: "\276F";
          }
        }
      }
    }
  }
}

app-dangerous-good {
  .ng-select,
  .ng-select div,
  .ng-select input,
  .ng-select span {
    display: flex;
    flex-wrap: wrap;
  }

  .ng-dropdown-panel {
    box-shadow: 0 0 0 1px #40a9ff;
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 10px;
  }

  .ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-option.ng-option-selected.ng-option-marked {
    color: rgba(0, 0, 0, 0.65);
    background-color: #e6f7ff;
    width: 195;
  }

  .ng-dropdown-panel .scroll-host {
    padding: 25px;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background-color: white;
  }

  .ng-dropdown-panel
    .ng-dropdown-panel-items
    .ng-option.ng-option-selected.ng-option-marked {
    width: 200;
    background-color: white;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    width: 200;
    padding: 5px 6px;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: white;
  }

  .ng-select .ng-clear-wrapper {
    display: none;
  }

  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value
    .ng-value-icon {
    color: var(--error);
    font-size: 23px;
    margin-top: -1px;
  }

  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value {
    flex-direction: row-reverse;
    background-color: #c4e2f9;
    border-radius: 5px;
    font-family: AvertaPE;
    font-size: 14px;
  }

  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value
    .ng-value-label {
    padding: 0px 2px 0 5px;
    font-size: 12px;
    font-family: AvertaPE;
    font-weight: normal;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .ng-select.ng-select-opened > .ng-select-container {
    box-shadow: 0 0 0 1px #40a9ff;
    height: 48px;
  }

  .ng-arrow-wrapper {
    align-items: center !important;
  }

  .upload-certificate-field app-input div {
    height: 50px;
  }

  .un-number-field input {
    height: 31px;
  }
}

app-quote-routing-details {
  .error .radio-button-card,
  .error .circle {
    border: 1px solid var(--error) !important;
  }
}

app-manage-dashboard-widgets {
  mat-expansion-panel {
    .popover-body {
      padding: 1px 7px !important;
    }

    ngb-popover-window {
      width: 245px !important;
    }

    mat-expansion-panel-header {
      .mat-expansion-indicator::after {
        color: var(--primary);
      }

      &:hover {
        background: none !important;
      }
    }
  }

  .popover-body {
    padding: 6.2px 9.6px;
  }

  ngb-popover-window {
    width: 215.4px;
  }

  .last-pinned-widget {
    div {
      margin-bottom: 0px !important;
    }
  }
}

app-manage-dashboard-widgets {
  display: grid;
  height: 100%;
  grid-template-rows: min-content auto min-content;

  mat-dialog-content {
    max-height: none !important;
  }
}

app-quote-summary {
  app-section-title {
    .wrapper .body {
      margin-left: 0px !important;
    }

    .wrapper .title-wrapper {
      padding-top: 40px;
      padding-bottom: 20px;
    }
  }
}

app-manage-dashboard-widgets {
  app-page-header {
    padding: 16px 41px 0px 41px;

    .page-header {
      font-family: AvertaPE;
    }

    &.mat-dialog-title {
      margin-bottom: 29px;
    }
  }
}

app-operational-statistics-section {
  .mat-drawer-inner-container {
    overflow: hidden;
    min-width: 711px !important;
  }
}

app-nested-drop-down-list {
  mat-form-field .mat-form-field-wrapper {
    .mat-form-field-underline {
      display: none;
    }

    .mat-form-field-flex .mat-form-field-infix {
      border-top: none;
    }
  }

  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-label {
    transform: none !important;
    opacity: 0.6;
    font-size: 14px;
    font-family: AvertaPE;
  }

  .mat-input-element {
    opacity: 1;
  }

  .mat-focused {
    .mat-form-field-label {
      color: black !important;
      opacity: 0.5;
    }
  }
}

mat-option.ongoing-activities-default-option {
  color: rgba(153, 153, 153, 0.65);
}

mat-tree mat-tree-node {
  min-height: 34px !important;
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 16px !important;
  font-family: AvertaPE !important;

  .expand-collapse-arrow {
    border: none;
    background-color: white;
  }

  mat-icon {
    &.collapsed-arrow-icon {
      transform: rotate(180deg) translate(-5px, -8px);
    }

    &.expanded-arrow-icon {
      transform: rotate(270deg) translate(-2px, -3px);
    }
  }
}

.ongoing-activities-mat-tree-container {
  bottom: -11.958px;
  left: -10.333px;
}

.custom-mat-dialog-container .mat-dialog-container {
  box-shadow: 0 3px 20px 0 rgba(33, 152, 221, 0.1);
  border: solid 1px var(--primary);
}

app-status-list input[type="radio"] {
  border: 0px;
  width: 20px;
  height: 20px;
}

.status-list-mat-dialog .mat-dialog-container {
  padding: 0px;
}

app-trucking-rate {
  .quantity-input-con {
    height: 40px;
    width: 164px;
  }

  .default-border {
    border-radius: 5px !important;
  }

  .mat-button-wrapper {
    margin: 0 0 13px 0;
  }

  .custom-datepicker {
    .mat-form-field-flex {
      padding: 0 0 0 11px;
      height: 40px;
      background-color: white !important;

      .mat-form-field-infix input {
        font-size: 14px;
      }
    }

    &.valid-field {
      .mat-form-field-flex {
        border: solid 1px var(--border);
      }
    }

    &.error {
      .mat-form-field-flex {
        border: 1px solid red !important;
      }
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      border-top: 0;
    }

    .mat-form-field-appearance-fill .mat-form-field-flex {
      padding: 0;
    }

    .mat-form-field-underline {
      display: none;
    }
  }

  app-quantity-input .quantity-input-con {
    input::placeholder {
      color: rgba(71, 76, 78, 0.5);
    }
  }

  .combined-fields {
    .input-field.error {
      border: 1px solid red !important;
      border-radius: 5px;
    }

    .rate-basis .ng-select-container .ng-value-container {
      display: block;
    }
  }
}

app-input-number.ng-valid[required],
.ng-valid.required {
  border-left: initial;
  /* Reset the border-left property to its initial value */
}

app-shipment-details {
  app-shipment-routing-section {
    .body {
      margin: auto 3%;
    }
  }

  app-cargo-details-section {
    .body {
      margin: auto 3%;
    }
  }

  mat-tab-group {
    width: 100%;

    .mat-tab-label,
    .mat-tab-label-active {
      min-width: 13% !important;
      margin-left: 74px !important;
    }

    .mat-tab-body {
      overflow: hidden !important;

      .shipment-details-tab .title svg {
        vertical-align: baseline;
      }
    }
  }

  .display-non-header .mat-tab-header {
    border-bottom: none !important;
  }

  .bill-warrning-message {
    .warning-badge {
      height: fit-content;
    }
  }
}

app-account-management-rate {
  .combined-fields {
    .dropdown-list-field {
      .ng-select-container {
        border-left: 0px !important;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        height: 40px;
        border-top: 1px solid var(--border) !important;
        border-right: 1px solid var(--border) !important;
        border-bottom: 1px solid var(--border) !important;
        background-color: #d7e0e5 !important;
      }
    }

    .ng-select .ng-arrow-wrapper {
      display: none;
    }
  }

  .ng-select-container {
    background-color: white !important;
    height: 40px;
    border: solid 1px var(--border) !important;
  }
}

app-freight-route-form,
app-land-freight-route-form {
  .freight-routes-ddl {
    border-radius: 5px;

    .ng-select-container {
      width: 269px;
      padding: 12px 17.5px 9px 21px;
    }

    &.valid-field {
      border: solid 1px #aab6be !important;
    }

    &.error {
      border: 1px solid var(--error) !important;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
      padding: 15px 19px;
    }
  }

  .rate {
    &:disabled {
      background-color: var(--disable-bg);
    }
  }

  .rate-curreny .rate {
    &.error {
      border: 1px solid var(--error) !important;
    }
  }

  .currency,
  .freight-routes-ddl {
    .ng-select-container {
      height: 40px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: var(--white) !important;

      .ng-value-container .ng-placeholder {
        color: var(--subtext);
        opacity: 0.5;
      }
    }

    .ng-dropdown-panel {
      margin: 0px;
    }

    .ng-dropdown-panel.ng-select-bottom {
      border-top-color: transparent;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
    }

    .ng-dropdown-panel.ng-select-top {
      border-bottom-color: transparent;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:first-child {
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
    }

    .ng-dropdown-panel {
      border: 1px solid var(--primary);
      border-radius: 5px;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
      background-color: white;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
      background-color: #e6f7ff;
    }

    .ng-dropdown-panel .scroll-host {
      border-radius: 5px !important;
    }

    .ng-option-selected {
      display: flex;

      .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        border-color: var(--primary) !important;

        &:after {
          content: "";
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: var(--primary);
        }
      }
    }
  }

  .currency {
    .ng-select-container {
      .ng-value-container {
        justify-content: center;
      }

      .ng-arrow-wrapper {
        display: none;
      }
    }
  }

  .ng-select.ng-select-opened .ng-select-container {
    z-index: 1051;
  }

  .error {
    .ng-select-container {
      box-shadow: 0 0 0 0.7px red;
    }
  }

  .custom-datepicker {
    .mat-form-field-flex {
      align-items: flex-end;
      width: 269px;
      height: 40px;
      padding: 1px 3px 3px 20px;
      border-radius: 5px !important;
      background-color: var(--white) !important;
    }

    &.valid-field .mat-form-field-flex {
      border: solid 1px var(--border);
    }

    &.error {
      border: 1px solid var(--error) !important;
      border-radius: 5px !important;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      border-top: 0;
      padding: 0.25em 0 0.63em 0;
      font-family: AvertaPE;
      font-size: 14px;
      color: var(--subtext);
    }

    .mat-form-field-suffix mat-datepicker-toggle button .mat-button-wrapper {
      margin-bottom: 4px;
    }

    .mat-form-field-appearance-fill .mat-form-field-flex {
      padding: 0;
    }

    .mat-form-field-underline {
      display: none;
    }

    ::placeholder {
      color: var(--subtext);
      /* Placeholder text color */
      opacity: 0.5;
    }
  }

  .rate-curreny,
  app-quantity-input .quantity-input-con {
    input::placeholder {
      color: rgba(71, 76, 78, 0.5);
    }
  }

  app-quantity-input {
    width: 164px;
    height: 40px;

    &.error {
      .quantity-input-con {
        border: 1px solid var(--error) !important;
        border-radius: 5px;
      }
    }

    .quantity-input-con {
      height: 40px;

      &.default-border {
        border-radius: 5px;
      }
    }
  }
}

app-customs-clearance-fcl-rate,
app-customs-clearance-not-fcl-rate,
app-customs-clearance-rate {
  ::placeholder,
  .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    font-size: 14px;
    opacity: 0.5;
    color: var(--subtext);
  }
}

app-ior-eor-rate,
app-customs-clearance-fcl-rate,
app-customs-clearance-not-fcl-rate,
app-trucking-rate,
app-additional-charges {
  .ng-select-container {
    background-color: white !important;
    border: solid 1px var(--border) !important;
    height: 40px;
    border-radius: 5px !important;
  }

  .ng-select.ng-select-disabled > .ng-select-container {
    background-color: var(--disable-bg) !important;
  }

  .combined-fields {
    .dropdown-list-field {
      .ng-select-container {
        border-left: 0px !important;
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }
    }

    .currency .ng-select-container .ng-value-container {
      justify-content: center;
    }

    .error {
      .ng-select-container {
        border: 1px solid red !important;
        border-radius: 5px;
      }
    }

    .ng-select .ng-arrow-wrapper {
      display: none;
    }
  }

  .error {
    .ng-select-container {
      border: 1px solid red !important;
      border-radius: 5px;
    }
  }

  .radio-select {
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
      padding: 15px 19px;
    }
  }

  .ng-dropdown-panel {
    margin: 0px;
  }

  .ng-dropdown-panel.ng-select-bottom {
    border-top-color: transparent;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }

  .ng-dropdown-panel.ng-select-top {
    border-bottom-color: transparent;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .ng-select.ng-select-opened .ng-select-container {
    z-index: 1051;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:first-child {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }

  .ng-dropdown-panel {
    border: 1px solid var(--primary);
    border-radius: 5px;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: white;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background-color: #e6f7ff;
  }

  .ng-dropdown-panel .scroll-host {
    border-radius: 5px !important;
  }

  .ng-option-selected {
    display: flex;

    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: var(--primary) !important;

      &:after {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--primary);
      }
    }
  }
}

app-add-domestic-trucking-information {
  app-input {
    .default-border {
      width: 881px !important;
      height: 48px !important;
      background-color: white !important;
    }

    .field-disabled {
      background-color: var(--disable-bg) !important;
    }
  }

  app-quantity-input {
    .quantity-input-con {
      height: 40px !important;
    }

    .validation-error {
      border-color: red !important;
    }

    .disabled-input {
      background-color: var(--disable-bg) !important;

      input:disabled,
      &.disabled {
        background-color: var(--disable-bg) !important;
      }
    }
  }

  .ng-select .ng-select-container {
    height: 40px !important;
    background-color: white !important;
  }
}

app-freight-routes,
app-customs-clearance-rates,
app-ior-eor-rate,
app-trucking-rate,
app-additional-charges {
  app-quantity-input {
    .disabled-quantity,
    .disabled-input div {
      background-color: var(--disable-bg) !important;

      input:disabled,
      &.disabled {
        background-color: var(--disable-bg);
      }
    }
  }

  .input-field-container,
  .text-field {
    input:disabled {
      background-color: var(--disable-bg) !important;
    }
  }

  .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
    background-color: var(--disable-bg) !important;
  }

  .disabled-date {
    .mat-form-field-flex {
      background-color: var(--disable-bg) !important;
    }
  }
}

app-quote-details {
  app-quote-card {
    .card-container {
      width: 1110px !important;
    }
  }

  mat-tab-group {
    mat-tab-body {
      margin-top: 24.6px !important;
    }
  }

  .Proposal-Summary {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: var(--secondary);
  }
}

app-service-rates-expandable-header {
  .check-icon-container {
    .mat-checkbox-inner-container {
      margin-right: 30px;
    }

    label {
      margin-bottom: 0px;
    }
  }
}

app-freight-option-card {
  .mat-radio-outer-circle {
    background-color: var(--white);
    border-width: 1px;
  }

  mat-radio-button.radio-button-error {
    .mat-radio-outer-circle {
      border: 1px solid var(--error) !important;
    }
  }
}

app-quote-list-tabs,
app-product-registration-list {
  mat-tab-group {
    width: 100%;

    .mat-tab-label,
    .mat-tab-label-active {
      min-width: 0% !important;
      margin-right: 121px !important;
      margin-left: 35px !important;
      height: 48px !important;
    }

    .last-tab {
      app-quote-tab {
        app-quote-card {
          .card-container {
            width: 1144px !important;
          }
        }
      }
    }
  }

  mat-tab-body {
    margin-top: 20px;
    overflow-y: hidden;
  }

  .mat-tab-body-wrapper {
    overflow-x: scroll;
    overflow-y: hidden;

    .mat-tab-body.mat-tab-body-active {
      overflow-x: visible !important;
      overflow-y: visible !important;
    }
  }
}

app-approvals-list {
  .mat-tab-body.mat-tab-body-active {
    overflow-y: hidden !important;
  }
}

app-quote-proposal-versions {
  mat-tab-group mat-tab-body {
    margin-top: 30px !important;
  }
}

.user-profile-ddl {
  padding: 2px 8px 2px 2px;
  border-radius: 5px !important;
  box-shadow: 6px 6px 20px -8px rgba(0, 0, 0, 0.25);
  border: solid 1px #a7b6bf;
}

app-totals-detailed-packages-information-selection,
app-cargo-handling {
  .mat-radio-outer-circle {
    background-color: var(--white);
    border: solid 1px #a7b6bf;
  }
}

app-totals-detailed-packages-information-selection,
app-cargo-handling {
  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    width: 24px;
    height: 24px;
    left: calc(50% - 12px);
    top: calc(50% - 12px);
  }
}

app-product-details {
  app-section-with-header {
    .accordionCon {
      width: 1170px;

      .body {
        display: none;
        transition: height 225ms cubic-bezier(0.4, 0, 0.2, 1);
        padding: 1% 0px 0% 0% !important;
        margin-left: 34px;

        &.active {
          display: flex;
        }
      }
    }
  }

  mat-tab-group {
    width: 100%;

    .mat-tab-label,
    .mat-tab-label-active {
      min-width: 9% !important;
      margin-left: 87px !important;
    }
  }

  app-error-badge {
    p {
      margin-bottom: 0px;
    }
  }
}

app-product-registration-details {
  .edit-section-button.disabled svg-icon path {
    fill: var(--inActive-tracking-line) !important;
  }
}

app-product-data-summary {
  .popover {
    max-width: 370px !important;
  }
}

app-quote-creation-information-bar {
  .older-version-icon {
    svg {
      margin-bottom: 3.5px;
    }
  }
}

app-product-information-form {
  .mat-radio-outer-circle {
    border: solid 1px var(--border);
    background-color: var(--white);
  }

  mat-radio-button.radio-button-error {
    .mat-radio-outer-circle {
      border: 1px solid var(--error) !important;
    }
  }

  .ng-select .ng-select-container .ng-value-container .ng-input > input {
    padding-top: 10px;
  }

  .ng-select .ng-clear-wrapper {
    display: none;
  }

  .part-number-field {
    ng-dropdown-panel .ng-dropdown-panel-items {
      max-height: 200px;

      .total-padding {
        max-height: 207px;
      }
    }
  }

  .ng-select.ng-select-opened > .ng-select-container {
    border: 1px solid var(--primary) !important;
    box-shadow: 0 0 0 0.7px var(--primary) !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items {
    border: 1px solid var(--primary) !important;
    margin-top: -4px !important;
  }

  select::-ms-expand {
    display: none;
  }
}

app-product-upload-documents {
  mat-radio-button {
    margin-top: 3px;

    .mat-radio-outer-circle {
      border-width: 1px;
    }

    .mat-radio-container {
      margin-top: 2px;
    }
  }

  .custom-datepicker {
    .mat-form-field-suffix {
      left: 10px;
      bottom: 1px;
    }
  }

  .mat-form-field-wrapper {
    padding-bottom: 0px;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: var(--white) !important;
    width: 355px;
    height: 50px;
    padding: 0px 25px;
    border-radius: 5px;
  }

  .mat-form-field-underline {
    display: none;
  }

  .ng-select {
    .ng-select-container {
      cursor: default;
      display: flex;
      outline: none;
      overflow: hidden;
      position: relative;
    }

    &.documentType {
      .ng-select-container {
        width: 540px;
      }
    }

    &.link-existing-document-drop-down {
      .ng-select-container {
        width: 343px;

        .ng-option-selected {
          .circle {
            display: flex;
            justify-content: center;
            align-items: center;
            border-color: var(--primary) !important;

            &:after {
              content: "";
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: var(--primary);
            }
          }
        }
      }
    }
  }

  .ng-dropdown-panel {
    width: 97%;
  }

  .ng-dropdown-panel.ng-select-bottom {
    top: 100%;
    border-top-color: #f3f3f3;
    width: 100%;
  }

  .ng-select-container {
    height: 48px;
    padding: 10px 0px 0px 30px;
    background-color: #fff !important;
  }

  .ng-select .ng-placeholder {
    margin: 14px 0px 16px 26px;
    padding: 0px 17px 0px 5px;
    line-height: 1.43;
    font-size: 15px;
    letter-spacing: normal;
    font-family: sans-serif;
    overflow: scroll;
  }

  .ng-select .ng-select-container .ng-value-container .ng-input > input {
    padding: 10px;
  }

  .mat-progress-bar {
    height: 9px;
    border-radius: 7px;
    margin-top: 11px;
  }
}

app-product-registration-list {
  app-page-header {
    .Group-19528 {
      width: 44.5px;
      height: 4px !important;
      margin: 10px 146px 0 1.5px;
      object-fit: contain;
    }
  }

  .mat-tab-label {
    margin-top: 0px !important;
    padding-top: 0px !important;
    height: fit-content;
  }

  .mat-tab-body-wrapper {
    flex-direction: column;
  }
}

app-shipment-progress {
  .popover {
    padding: 25px !important;
  }

  .popover-body {
    padding: 0px !important;
  }
}

app-request-product-registration,
app-request-new-product {
  app-page-stepper {
    .timeline-container {
      .line {
        width: 100% !important;
        left: 103px !important;
      }

      .timeline-group {
        width: 33% !important;
      }
    }
  }
}

app-my-profile {
  .profile-picture-section app-section-with-header {
    .body {
      margin-left: 0px !important;
    }
  }
}

app-change-request-activity {
  .change-activity-ddl
    .ng-select-container
    .ng-value-container
    .ng-placeholder {
    font-weight: 300;
    color: rgba(71, 76, 78, 0.5);
  }

  .acceptance-number-field div {
    border-radius: 5px !important;
    width: 490px !important;
    height: 46px;
    background-color: var(--field-bg) !important;
  }

  .registration-license-field div {
    border-radius: 5px !important;
    width: 292px !important;
    height: 46px;
    background-color: var(--field-bg) !important;
  }
}

app-summary {
  .popover {
    max-width: 282px !important;
  }
}

.custom-confirmation-action-pop {
  .mat-dialog-container {
    padding: 50px 40px 50px 40px;
  }
}

app-g-search-bar {
  .mat-checkbox-layout .mat-checkbox-label {
    line-height: 16px;
  }

  .mat-checkbox-inner-container {
    width: 15px !important;
    height: 15px !important;
    margin-top: 0.8px !important;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 32px;
  }

  .mat-button-toggle-appearance-standard {
    border-radius: 15px;
    width: 50px;
    height: 34px;
    background-color: var(--bg);
  }

  .mat-button-toggle-group-appearance-standard {
    border-radius: 15px;
    background-color: var(--bg);
    border: none;
  }

  .mat-button-toggle-checked {
    background-color: var(--primary);
  }

  .mat-button-toggle-group-appearance-standard
    .mat-button-toggle
    + .mat-button-toggle {
    border-left: none;
  }

  .mat-button-toggle-focus-overlay {
    display: none;
  }

  .popover {
    padding: 3px 17px;
  }

  input:focus {
    outline: var(--primary) auto 1px;
  }

  .mat-checkbox-background,
  .mat-checkbox-frame {
    border: 1px solid var(--primary) !important;
  }
}

.custom-bulk-modal {
  min-height: 254px;
  max-height: 489px;
  overflow-y: auto;
}

app-shipment-card {
  .mat-checkbox-layout {
    margin-bottom: 0px !important;

    .mat-checkbox-inner-container {
      width: 15px !important;
      height: 15px !important;
    }
  }

  mat-checkbox.mat-checkbox-disabled {
    .mat-checkbox-frame {
      background-color: var(--disable-bg);
    }
  }
}

app-bulk-action {
  .mat-expansion-indicator {
    padding: 0 25px !important;

    &::after {
      color: var(--primary) !important;
    }
  }
}

app-status-list {
  .mat-radio-container .mat-radio-outer-circle {
    border-width: 1px;
  }
}

app-add-or-edit-employee {
  .ng-select .ng-select-container {
    min-height: 40px;
    border: solid 1px #e7e7e7 !important;
    background-color: #f5f5f5 !important;

    .ng-arrow-wrapper .ng-arrow {
      color: var(--text) !important;
    }
  }

  .assigned-accounts-field .ng-select-container .ng-value-container {
    max-width: 667px;
    max-height: 83px;
    overflow-y: auto;
  }

  .form-control {
    border: none;
  }

  .ng-select.ng-select-single .ng-select-container {
    height: 20px;
  }

  .assigned-accounts-field {
    .ng-select-container {
      min-height: 40px !important;

      .ng-value-container {
        .ng-placeholder {
          padding-left: 10px !important;
          margin-left: 0px !important;
        }

        .ng-input > input {
          padding: 0px !important;
        }
      }
    }

    ng-dropdown-panel .ng-dropdown-panel-items {
      max-height: 200px;

      .total-padding {
        height: 190px !important;
      }
    }
  }

  app-error-badge {
    .error-badge {
      width: 1200px;
    }
  }
}

.assigned-role-field {
  .ng-select-container .ng-value-container .ng-placeholder {
    padding-left: 0px !important;
  }
}

app-add-or-edit-employee,
app-product-information {
  ::placeholder {
    font-size: 14px;
    opacity: 0.5;
    color: #474c4e;
  }

  .ng-select .ng-select-container .ng-value-container .ng-input > input {
    padding: 10px;
  }

  .ng-select .ng-clear-wrapper {
    display: none;
  }

  .ng-invalid {
    border: 0px;
  }

  .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    font-size: 14px;
    color: #474c4e;
    opacity: 0.5;
    padding-left: 20px;
  }

  .hs-Product {
    .ng-dropdown-panel .ng-dropdown-panel-items {
      border: 1px solid var(--primary) !important;
      margin-top: -4px !important;
    }
  }

  .ng-select.ng-select-opened > .ng-select-container {
    border: 1px solid var(--primary) !important;
    box-shadow: 0 0 0 0.7px var(--primary) !important;
  }
}

app-add-or-edit-supplier {
  .services-ddl {
    .ng-select-container {
      padding-bottom: 0px !important;
    }
  }

  .country-field,
  .city-field {
    .ng-select-container {
      height: 40px;
    }
  }

  .ng-select {
    .ng-select-container {
      border: solid 1px #e7e7e7 !important;
      background-color: #f5f5f5 !important;
      padding-bottom: 3px;
      padding-top: 6px !important;

      .ng-value-container {
        .ng-placeholder {
          font-size: 12px !important;
        }

        .ng-value {
          font-size: 16px !important;
        }
      }
    }

    .ng-select.form-control.field-input.select-text .ng-value .ng-value-label {
      opacity: 0.6;
      font-family: AvertaPE;
      font-size: 12px;
      text-align: left;
      color: var(--subtext);
    }

    .ng-arrow-wrapper .ng-arrow {
      color: var(--text) !important;
    }

    .ng-select-container .ng-value-container .ng-input > input {
      padding-top: 7px;
    }

    *::-webkit-scrollbar-thumb {
      background-color: #2a384b !important;
      opacity: 1;
      width: 2px !important;
    }
  }
}

app-add-edit-role,
app-add-edit-plan,
app-pricelist-clearance-details,
app-customs-clearnace-pricing-details {
  .ng-select-container {
    height: 40px !important;
    border-radius: 5px !important;
    border: solid 1px #e7e7e7 !important;
    background-color: #f5f5f5 !important;
    padding: 8px !important;
    padding-left: 16px !important;
    color: var(--subtext);

    .ng-arrow-wrapper .ng-arrow {
      color: var(--header-bg) !important;
    }
  }

  .currency {
    .ng-select-container {
      padding-left: 8px !important;
    }
  }

  .ng-select {
    width: 272px !important;
  }

  .ng-value {
    font-size: 16px !important;
  }
}

app-customs-clearnace-pricing-details {
  .ng-select-container {
    border-left: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    color: var(--subtext);

    .ng-arrow-wrapper {
      display: none !important;
    }
  }
}

app-add-edit-role,
app-add-edit-plan,
app-create-approval-information {
  ng-dropdown-panel {
    z-index: 3 !important;
  }
}

app-quote-list-tabs {
  .quote-tabs {
    mat-tab-group {
      .mat-tab-header
        .mat-tab-label-container
        .mat-tab-list
        .mat-tab-labels
        .mat-tab-label
        .mat-tab-label-content {
        font-size: 15px;
      }
    }
  }
}

app-create-quote-proposal {
  app-quote-card {
    .card-container {
      width: 1110px !important;
    }
  }
}

app-involved-parties {
  .ddl-border {
    .ng-dropdown-panel .ng-dropdown-panel-items {
      margin-top: -4px !important;
    }
  }

  .ng-dropdown-panel.ng-select-top,
  .ng-dropdown-panel.ng-select-bottom {
    display: flex;
    flex-direction: column-reverse;
  }

  .ng-dropdown-panel .ng-dropdown-footer {
    padding: 0.7rem 1.5rem 0.3rem 0.7rem !important;
    background-color: var(--field-bg);
    color: var(--primary);

    p {
      margin-bottom: 0.4rem;
    }
  }
}

app-routing-details-form {
  .shipment-routing-fields,
  .port-border {
    .ng-dropdown-panel .ng-dropdown-panel-items {
      border: 1px solid var(--primary) !important;
      margin-top: -4px !important;
    }
  }
}

app-reset-password {
  #reset-password::-ms-reveal,
  #reset-password::-ms-clear,
  #reset-confirm-password::-ms-reveal,
  #reset-confirm-password::-ms-clear {
    display: none !important;
  }
}

app-supplier-tabs {
  .mat-expansion-panel {
    background: none;
  }

  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: none;
  }

  .mat-expansion-panel:not(.mat-expanded)
    .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
    background: none;
  }

  .mat-nav-list .mat-list-item:hover {
    background: none;
  }

  .mat-list-base .mat-list-item,
  .mat-list-base .mat-list-option {
    height: 23px;
    padding-bottom: 8px;
    font-size: 12px;
    font-family: AvertaPE !important;
  }

  .mat-list-base .mat-list-item .mat-list-item-content {
    padding: 5px;
    padding-left: 15px;
  }

  .mat-expansion-panel-header.mat-expanded {
    height: 22px;
  }

  .mat-expansion-panel-header {
    padding: 0px;
    height: 22.5px;
    font-size: 16px;
    font-family: AvertaPE !important;
  }

  .mat-expansion-panel-header-title {
    margin-left: 0px;
  }

  .mat-expansion-indicator::before {
    vertical-align: 10px;
    margin-top: 14px;
  }

  .mat-expansion-indicator::after {
    vertical-align: 10px;
    margin-top: 14px;
  }

  .mat-content {
    flex: 0.6;
  }
}

app-trucking-pricelist,
app-customs-clearance-pricelist {
  app-add-empty-data-msg {
    .no-data-container {
      padding-top: 29px !important;
    }
  }

  app-g-search-bar {
    .search-bar-container {
      margin-top: 14px !important;
    }

    .search-input {
      padding-right: 50px !important;
    }
  }
}

app-trucking-pricelist {
  datatable-row-wrapper {
    width: 100%;
  }
}

app-trucking-pricelist,
app-employee-list,
app-customs-clearance-pricelist {
  datatable-body-row {
    cursor: pointer;
    width: 100% !important;
  }
}

app-product-variants {
  .ng-select-container {
    height: 40px;
    border: solid 1px #e7e7e7 !important;
    border-radius: 5px;
    background-color: var(--white);
    font-size: 14px;
  }

  .ng-option-selected {
    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: var(--primary) !important;

      &:after {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--primary);
      }
    }
  }

  .error {
    border: 1px solid var(--error) !important;
    border-radius: 5px;
  }
}

app-add-edit-trucking-pricelist,
app-pricelist-contractual-terms,
app-add-edit-trucking-route,
app-payment-terms,
app-add-edit-truck-details,
app-add-edit-activity {
  .ng-select-container {
    height: 40px;
    border-radius: 5px;
    border: solid 1px #e7e7e7 !important;
    background-color: #f5f5f5 !important;
  }

  .ng-select.ng-select-opened > .ng-select-container {
    box-shadow: none;
  }

  .ng-select .ng-arrow-wrapper .ng-arrow {
    color: var(--header-bg) !important;
  }

  .ng-option-selected {
    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: var(--primary) !important;

      &:after {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--primary);
      }
    }
  }

  .error {
    border: 1px solid var(--error) !important;
    border-radius: 5px;
  }

  .combined-fields {
    .error {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    .ng-select-container {
      border-left: 0px !important;
      border-bottom-left-radius: 0px !important;
      border-top-left-radius: 0px !important;
    }
  }
}

app-add-edit-truck-details {
  .ng-select-container {
    border: solid 1px var(--white) !important;
    background-color: var(--white) !important;

    .ng-value-container {
      .ng-input > input {
        padding-top: 6px !important;
      }
    }
  }

  .truck-details-container {
    .combined-fields {
      .rate {
        background-color: var(--white) !important;
      }

      .field {
        .ng-select-container {
          .ng-arrow-wrapper {
            display: none !important;
          }
        }
      }
    }
  }
}

app-supplier-documents,
app-documents-list {
  .uploaded-document-container {
    .actions {
      width: 26% !important;
    }
  }
}

app-create-approval-information {
  app-section-with-header {
    .accordionCon .body {
      padding: 0 !important;
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items {
    border: 1px solid var(--primary) !important;
    margin-top: -2px !important;
    border-radius: 5px;
  }

  .ng-select.ng-select-opened > .ng-select-container {
    border: 1px solid var(--primary) !important;
    box-shadow: 0 0 0 0.7px var(--primary) !important;
  }

  .ng-select {
    .ng-select-container {
      border-radius: 5px !important;

      .ng-value-container {
        padding-bottom: 0px !important;

        .ng-placeholder,
        .ng-value {
          font-size: 14px !important;
          border: none !important;
        }
      }
    }

    *::-webkit-scrollbar-thumb {
      background-color: var(--border) !important;
      opacity: 1;
      width: 3px !important;
    }

    .ng-dropdown-panel-items {
      .ng-option {
        font-size: 14px;
        padding-bottom: 0 !important;
        padding-top: 15px !important;

        app-checkbox {
          margin-right: 8px;
          margin-top: -3px;
        }
      }
    }
  }

  .products {
    .ng-dropdown-panel-items .ng-option {
      padding: 5px 12px !important;
    }
  }

  .field-input,
  .field-input-larger {
    .mat-radio-container {
      margin-top: -6px;
    }

    .mat-radio-outer-circle {
      border: solid 1px var(--border);
    }
  }

  .registration-license-number-field {
    .ng-select-container {
      .ng-input {
        padding-top: 9px;
      }
    }

    ng-dropdown-panel .ng-dropdown-panel-items {
      max-height: 200px;

      .total-padding {
        height: 190px;
      }

      .ng-option {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
      }
    }
  }
}

app-add-edit-approval-price {
  .approvals-field {
    .ng-select-container {
      height: 39px !important;
    }
  }

  .ng-select-container {
    .ng-value-container .ng-input > input {
      padding-top: 6px !important;
    }
  }
}

app-add-edit-approval-price,
app-customs-clearance-container-pricing-details {
  .ng-select-container {
    background-color: var(--white) !important;
    height: 40px;
    border-radius: 5px;
    border: solid 1px #e7e7e7 !important;
  }

  .ng-select .ng-arrow-wrapper .ng-arrow {
    color: var(--header-bg) !important;
  }

  .ng-option-selected {
    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: var(--primary) !important;

      &:after {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--primary);
      }
    }
  }

  .error {
    border: 1px solid var(--error) !important;
    border-radius: 5px;
  }

  .combined-fields {
    .error {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    .ng-select-container {
      background-color: var(--white);
      border-left: 0px !important;
      border-bottom-left-radius: 0px !important;
      border-top-left-radius: 0px !important;
      color: var(--subtext);

      .ng-arrow-wrapper {
        display: none !important;
      }
    }
  }
}

app-customs-clearance-container-pricing-details {
  .ng-select-container {
    background-color: #f5f5f5 !important;

    .ng-value-container .ng-input > input {
      padding-top: 7px !important;
    }
  }
}

app-module-widget {
  .widget-header app-page-header .page-header span {
    font-size: 18px !important;
  }

  .tabs-container .mat-tab-group .mat-tab-body-wrapper {
    .mat-tab-body {
      margin-top: 0px !important;
    }
  }

  app-add-empty-data-msg {
    .no-data-container {
      padding-bottom: 0px !important;
      padding-top: 0px !important;
    }
  }

  mat-tab-header {
    padding: 0px 35px !important;

    .mat-tab-label-container {
      .mat-tab-label,
      .mat-tab-label-active {
        min-width: 0% !important;
        font-size: 14px !important;
      }

      .mat-tab-label {
        font-weight: normal !important;
        margin-right: 60px !important;
        padding: 0 !important;
      }
    }
  }

  .mat-tab-body-content {
    min-height: 0px !important;
    height: 258px !important;
    margin-bottom: 15px;
  }

  .card-footer {
    .arrow-button {
      font-size: 14px;
    }
  }
}

app-product-group {
  .ng-select .ng-select-container {
    height: 40px;
    background-color: white !important;
  }

  .mat-checkbox {
    padding-right: 5px;
  }

  .mat-checkbox-inner-container {
    width: 18px !important;
    height: 18px !important;
  }

  .mat-checkbox-layout {
    margin-bottom: 0px;
  }

  .multiple-selection-ddl {
    .ng-select .ng-select-container {
      max-height: 40px;
      overflow-y: auto;
    }

    .ng-select.ng-select-multiple
      .ng-select-container
      .ng-value-container
      .ng-input {
      height: 0px;
    }

    .ng-select .ng-select-container .ng-value-container .ng-input > input {
      height: 0px;
      padding: 0px;
    }

    .ng-select.ng-select-multiple
      .ng-select-container
      .ng-value-container
      .ng-value {
      margin-bottom: 3px;
    }

    .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
      margin: auto;
    }
  }
}

app-add-or-edit-company {
  .freepl-details-ddl {
    .ng-select-container {
      height: 40px;
      border: solid 1px #e7e7e7 !important;
      background-color: #f5f5f5 !important;
    }

    .ng-select.form-control.field-input.select-text .ng-value .ng-value-label {
      opacity: 0.6;
      font-family: AvertaPE;
      font-size: 12px;
      text-align: left;
      color: var(--subtext);
    }

    .ng-arrow-wrapper .ng-arrow {
      color: var(--text) !important;
    }

    .ng-select-container .ng-value-container .ng-input > input {
      font-size: 14px !important;
      padding-top: 7px;
    }

    *::-webkit-scrollbar-thumb {
      background-color: var(--header-bg) !important;
      opacity: 1;
      width: 2px !important;
    }
  }

  input {
    font-size: 14px !important;
  }
}

app-quotes-widget {
  app-module-widget {
    mat-tab-body {
      margin-top: 0px !important;
    }
  }
}

app-employee-list {
  .datatable-body {
    border: 1px solid #bebebe;
  }

  .ngx-datatable.material
    .datatable-body
    .datatable-body-row
    .datatable-body-cell {
    font-weight: 300;
    font-size: 14px;
    color: var(--subtext);
  }
}

app-company-service-details {
  .ng-select .ng-select-container {
    width: 272px;
    height: 40px;
    border-radius: 5px;
    border: solid 1px #e7e7e7 !important;
    background-color: #f5f5f5 !important;
  }

  .ng-select .ng-arrow-wrapper .ng-arrow {
    color: var(--header-bg) !important;
  }
}

app-product-registration-service-pricing-details {
  .ng-select.ng-select-clearable
    .ng-select-container.ng-has-value:hover
    .ng-clear-wrapper {
    height: 20px;
    background-color: var(--border);
  }

  .ng-select-container {
    width: 212px !important;
    height: 40px !important;
    border-radius: 5px !important;
    border: solid 1px #e7e7e7 !important;
    background-color: var(--white) !important;
    padding: 8px !important;
    padding-left: 16px !important;
    color: var(--subtext);

    .ng-arrow-wrapper .ng-arrow {
      color: var(--header-bg) !important;
    }

    .ng-value-container input {
      padding-top: 7px !important;
    }
  }

  .currency {
    .ng-select-container {
      padding: 0px 0px 0px 10px !important;
      width: 50px !important;
      border: none !important;
    }

    .ng-arrow-wrapper,
    .ng-arrow-wrapper .ng-arrow {
      display: none;
      position: absolute !important;
    }
  }
}

app-suppliers-list {
  .datatable-body {
    border: 1px solid #bebebe;
  }

  .ngx-datatable.material
    .datatable-body
    .datatable-body-row
    .datatable-body-cell {
    font-weight: 300;
    font-size: 14px;
    color: var(--subtext);
  }

  .ngx-datatable.scroll-vertical .datatable-body {
    overflow-x: hidden;
  }
}

app-add-edit-approval-services-pricing-details,
app-add-or-edit-approvals-service {
  .ng-select-container {
    background-color: var(--white) !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;

    .ng-arrow-wrapper {
      display: none !important;
    }
  }

  .ng-select.ng-select-disabled > .ng-select-container {
    background-color: #e7e7e7 !important;
  }
}

app-customer-selected-services {
  .services-table ngx-datatable datatable-body {
    datatable-body-row,
    datatable-row-wrapper {
      width: 100% !important;
    }

    .datatable-body-cell-label {
      color: var(--subtext);
      font-weight: 300;

      .service-checkbox-container
        .mat-checkbox-disabled
        .mat-checkbox-inner-container {
        border-radius: 4px;
        background-color: var(--disabled-check-box);
      }
    }
  }
}

app-trucking-routes {
  .container {
    .search-filter-add-container {
      .search-filter-container {
        app-admin-search-bar {
          div {
            .search-container {
              position: unset;

              .search-icon {
                position: absolute !important;
                left: 3% !important;
                top: 9px !important;
              }
            }
          }
        }
      }
    }
  }

  mat-sidenav-container.mat-sidenav-container mat-sidenav {
    .ng-select-container {
      height: 40px;
    }
  }

  datatable-body-row {
    cursor: pointer;
    width: 100% !important;
  }
}

app-countries-list {
  .container {
    .search-filter-add-container {
      .search-filter-container {
        app-admin-search-bar {
          div {
            .search-container {
              position: unset;

              .search-icon {
                position: absolute !important;
                left: 3% !important;
                top: 9px !important;
              }
            }
          }
        }
      }
    }
  }
}

app-trucking-route-search-with-details {
  ng-select.field {
    .ng-select-container {
      border-radius: 5px;
      border: solid 1px #e7e7e7 !important;
      background-color: #f5f5f5 !important;

      .ng-input > input {
        padding-top: 7px !important;
      }

      .ng-arrow-wrapper .ng-arrow {
        color: var(--header-bg) !important;
      }
    }

    .ng-option-selected {
      .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        border-color: var(--primary) !important;

        &:after {
          content: "";
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: var(--primary);
        }
      }
    }
  }

  .ngx-datatable.scroll-vertical {
    height: 100%;
  }

  datatable-body {
    max-height: 345px;
  }

  .page-count {
    display: none;
  }

  .ngx-datatable [hidden] {
    display: block !important;
  }
}

app-add-edit-customs-clearance-pricelist {
  input {
    font-size: 14px;
  }

  .ng-select {
    .ng-select-container {
      .ng-value-container {
        font-size: 14px;
      }
    }
  }
}

.input-error {
  border: 1px solid var(--error) !important;
}

app-add-edit-ior-or-eor-pricing-details,
app-add-edit-ior-or-eor-commission-caps,
app-add-edit-ior-or-eor-service {
  .ng-select {
    .ng-select-container {
      height: 40px;
      background-color: #f5f5f5 !important;
      border: 1px solid #e7e7e7 !important;
    }

    .ng-arrow-wrapper .ng-arrow {
      color: var(--header-bg) !important;
    }
  }

  .combined-fields {
    .ng-select .ng-select-container {
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
    }

    .ng-select.ng-select-opened > .ng-select-container .ng-arrow-wrapper {
      display: none;
    }

    .ng-select .ng-arrow-wrapper {
      display: none;
    }
  }

  .ddl-error .ng-select-container {
    border: 1px solid var(--error) !important;
  }
}

app-add-or-edit-account-management {
  .ng-select .ng-select-container {
    height: 40px !important;
    border-radius: 5px;
    border: solid 1px #e7e7e7 !important;
    background-color: #f5f5f5 !important;
    font-family: AvertaPE;
    font-size: 16px !important;
    color: var(--subtext);

    .ng-value-container {
      .ng-placeholder {
        opacity: 0.6;
      }
    }
  }

  .ng-select .ng-arrow-wrapper .ng-arrow {
    color: var(--header-bg) !important;
  }

  .combined-fields {
    .ng-select .ng-select-container {
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
    }

    .ng-select.ng-select-opened > .ng-select-container .ng-arrow-wrapper {
      display: none;
    }

    .ng-select .ng-arrow-wrapper {
      display: none;
    }
  }

  .ddl-error .ng-select-container {
    border: 1px solid var(--error) !important;
  }

  .ddl-error-input .ng-select-container {
    border-left: 1px solid #e7e7e7 !important;
  }
}

app-white-nav-bar {
  .popover {
    margin-top: 0;
    padding: 5px;

    .arrow {
      display: none;
    }
  }

  .admin-role-nav-bar-style {
    .notification-bell svg-icon svg {
      width: 25px !important;
      height: 25px !important;
    }
  }

  .single-company-ddl {
    .ng-arrow-wrapper {
      display: none;
    }
  }

  .companies {
    .ng-select-container {
      min-width: 100px;
      width: 143px;
      margin: 0.5px 15px 0px 15px;
      padding: 9.7px 10px 5px 5px;
      height: 35px;
      border-radius: 15px;
      background-color: var(--white) !important;
      border: 0.5px solid #b1b5b8 !important;

      .ng-value-container {
        .ng-value {
          color: white;
        }

        .ng-input > input {
          padding-top: 3px !important;
        }
      }

      .ng-arrow-wrapper {
        background-image: url("assets/images/SVG/arrow-down-dark-blue.svg");
        background-repeat: no-repeat;
        background-size: 11px 12px;
        margin-top: 0px;
        width: 14px;
        height: 14px;

        .ng-arrow {
          border-color: none !important;
          border-style: none !important;
          border-width: 0 !important;
        }
      }
    }

    ng-dropdown-panel .ng-dropdown-panel-items {
      max-height: 200px;

      .total-padding {
        max-height: 190px;
      }

      .scrollable-content {
        .ng-option {
          height: 40px;
        }
      }
    }
  }

  .btn:focus {
    box-shadow: none !important;
  }
}

.notifications-viewer {
  overflow: hidden !important;
  padding-bottom: 20px;
  min-width: 385px !important;
  margin-left: -294px;

  .notification-viewer-with-companies:before {
    right: 92%;
  }

  .notification-viewer-with-companies:before,
  .notification-viewer-without-companies:before {
    border-color: rgba(161, 103, 45, 0) !important;
    border-bottom-color: white !important;
    border-width: 11px !important;
    bottom: 100%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
  }

  .notification-viewer-without-companies:before {
    right: 88% !important;
  }

  &.customer-view:before {
    right: 2.5% !important;
  }
}

app-trucking-route-details-section {
  .ng-select .ng-select-container {
    height: 40px !important;
    border-radius: 5px;
    border: solid 1px #e7e7e7 !important;
    background-color: #f5f5f5 !important;
    font-family: AvertaPE;
    font-size: 16px !important;
    color: var(--subtext);

    .ng-value-container {
      .ng-placeholder {
        opacity: 0.6;
      }

      .ng-input > input {
        padding-top: 6px;
      }
    }
  }

  .ng-select .ng-arrow-wrapper .ng-arrow {
    color: var(--header-bg) !important;
  }
}

app-edit-trucking-route {
  app-trucking-route-details-section {
    .section {
      padding-bottom: 40px;
      margin-left: 17px;
    }
  }

  app-add-edit-truck-details {
    .truck-details-container {
      width: 937px !important;

      .header {
        padding-bottom: 0px !important;
      }

      .header .field:first-child,
      .inputs .field:first-child {
        width: 26px;
        margin-right: 54px !important;
      }

      .header .field:nth-child(n + 2):nth-child(-n + 4),
      .inputs .field:nth-child(n + 2):nth-child(-n + 4) {
        width: 212px !important;
        height: 40px !important;
        margin-right: 40px !important;
      }

      .ng-select-container {
        width: 212px !important;
        height: 40px !important;
      }

      .ng-select .ng-arrow-wrapper .ng-arrow {
        color: var(--header-bg) !important;
      }
    }
  }
}

app-assigned-business-partners-list {
  .ngx-datatable.material
    .datatable-body
    .datatable-body-row
    .datatable-body-cell {
    font-weight: 300;
  }
}

app-business-partner-search {
  .page-count {
    display: none;
  }

  .ngx-datatable [hidden] {
    display: block !important;
  }

  app-admin-search-bar {
    .search-container .search-input {
      width: 272px;
      border-radius: 5px;
      border: solid 1px #e7e7e7;
    }
  }
}

app-trucking-pricing-details {
  .ng-select-container {
    border-left: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    height: 39px !important;
    border-radius: 5px;
    border: solid 1px #e7e7e7 !important;
    background-color: var(--disable-bg) !important;
    font-family: AvertaPE;
    font-size: 14px !important;
    color: var(--subtext);
  }

  .enabled-ddl {
    .ng-select-container {
      background-color: #f5f5f5 !important;
      border-left: none !important;
    }
  }

  .ng-select .ng-arrow-wrapper {
    display: none;
  }
}

app-pricelist-terms-and-conditionts {
  .ng-select-container {
    border-left: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    height: 39px !important;
    border-radius: 5px;
    border: solid 1px #e7e7e7 !important;
    background-color: #f5f5f5 !important;
    font-family: AvertaPE;
    font-size: 14px !important;
    color: var(--subtext);
    border-left: none !important;

    .ng-value-container .ng-placeholder {
      opacity: 0.6;
    }
  }

  .ng-select .ng-arrow-wrapper {
    display: none;
  }
}

app-cities-list {
  .ngx-datatable.scroll-vertical .datatable-body {
    max-height: 350px;
  }
}

app-non-admin-sidebar,
app-admin-sidebar {
  width: 48px;
  position: fixed !important;
  z-index: 1030;
  box-sizing: unset;
  height: 100%;

  .dropdown-toggle::after {
    display: none !important;
  }

  .dropdown-button:focus {
    box-shadow: none !important;
  }

  .dropdown-button {
    padding: 0px !important;
  }

  .dropdown-menu {
    background: #3f4c5d;
    position: absolute !important;
    border-radius: 5px;
    min-width: 128px !important;
    max-width: 172px !important;
    overflow: hidden;
    margin: 5px 0;

    .dropdown-item {
      font-size: 14px;
      text-align: left;
      color: var(--white);
      padding: 7px 14px !important;
      cursor: pointer;
    }

    .dropdown-item:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  .selected-popover {
    margin-left: 1rem !important;
  }
}

app-add-or-edit-charge-type {
  .ng-select.ng-select-opened > .ng-select-container,
  ng-dropdown-panel-items scroll-host {
    box-shadow: 0 0 0 1px #384455;
  }

  .ng-dropdown-panel {
    border-radius: 20px;
    margin: -20px 0 0 0px;
    padding-top: 20px;
    z-index: 1000;
    border: solid 1px #384455;
    border-top: none;
    overflow: hidden;
  }

  .ng-dropdown-panel {
    box-shadow: unset;

    .ng-dropdown-panel-items .ng-option {
      padding: 18px;

      mat-radio-button {
        .mat-radio-label {
          margin-bottom: 0;
        }
      }
    }

    ng-select-multiple ng-star-inserted ng-select-bottom {
      overflow: hidden;
    }

    .scroll-host {
      max-height: 180px !important;
    }
  }

  .ng-select .ng-dropdown-panel-items scroll-host {
    overflow: scroll;
    height: 200px;
  }

  .ng-select {
    .ng-select-container {
      background-color: #f5f5f5 !important;
      padding-left: 11px;
    }

    .ng-arrow-wrapper .ng-arrow {
      color: #2e3c4f !important;
    }
  }

  .ng-invalid:not(form) {
    border-left: unset;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    padding-bottom: unset;

    .ng-input {
      height: 20px;
    }
  }
}

app-product-registration-document-fields {
  .mat-expanded {
    width: 716px !important;
    max-height: 232px !important;
    overflow-y: auto;
  }
}

.top-bar-container {
  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu.show {
    top: 5px !important;
  }

  .mat-radio-label {
    padding: 15px 25px;
    margin: 0;
    background-color: var(--bg);
    align-items: flex-start;

    &:hover {
      background-color: rgba(33, 152, 221, 0.1);
    }

    .mat-radio-outer-circle {
      border-color: var(--border);
    }
  }

  .mat-radio-label-content {
    font-family: AvertaPE;
    font-size: 14px;
    font-weight: 300;
    color: var(--inActive-tracking-line);
  }
}

app-network-list,
app-add-or-edit-network-member {
  .ng-select .ng-arrow-wrapper .ng-arrow {
    color: black !important;
  }

  .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    font-size: 12px;
  }

  .ng-select.search-row-ddl .ng-select-container {
    border: 1px solid #bebebe !important;
    background-color: var(--companiesDropDownInNavBar) !important;
    border-radius: 0px !important;
    height: 40px !important;
    width: 175px;
  }
}

app-add-or-edit-network-member {
  .ng-select .ng-select-container {
    border: 1px solid #e7e7e7 !important;
    border-radius: 5px;
    height: 40px !important;
    padding-left: 12px !important;
  }

  .dialog-form-margin .ng-select {
    &.ng-select-disabled .ng-select-container {
      background-color: var(--companiesDropDownInNavBar) !important;
    }

    .ng-select-container {
      background-color: #f5fafd !important;
      border-radius: 5px;
    }
  }
}

app-compliance-information {
  mat-radio-button {
    .mat-radio-outer-circle {
      border-width: 1px;
      border-color: var(--border) !important;
    }

    &.error .mat-radio-outer-circle {
      border-color: var(--error) !important;
    }
  }
}

app-product-information {
  .ng-select .ng-select-container {
    padding-left: 25px;

    .ng-value-container {
      .ng-placeholder {
        padding-left: 0px !important;
      }

      .ng-input > input {
        padding-top: 10px !important;
        padding-left: 13px !important;
      }
    }
  }

  .ng-option-selected {
    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: var(--primary) !important;

      &:after {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--primary);
      }
    }
  }

  .ng-dropdown-panel.ng-select-top,
  .ng-dropdown-panel.ng-select-bottom {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0px;
  }

  .ng-dropdown-panel .ng-dropdown-footer {
    padding: 0.7rem 1.5rem 0rem 0.7rem !important;
    background-color: var(--field-bg);
    color: var(--primary);

    p {
      margin-bottom: 0.4rem;
    }
  }

  .ng-select .hover-item {
    padding-left: 29px;
    font-size: 14px;
    font-weight: 100;
  }
}

app-add-edit-role,
app-add-edit-plan {
  .ng-select {
    width: 100% !important;
  }

  .ng-dropdown-panel.ng-select-bottom {
    border: solid 1px var(--border);
    padding: 12px 0 0 0;
    top: 26px;
    box-shadow: none;
    border-radius: 14px;
    overflow: hidden;
  }

  .mat-tab-body-content {
    overflow: unset !important;
    min-height: 100px;
  }

  .mat-tab-body.mat-tab-body-active {
    overflow-y: inherit;
  }

  .mat-tab-body-wrapper {
    height: unset !important;
  }
}

app-selected-product-in-tab {
  mat-expansion-panel {
    box-shadow: none !important;
    border-bottom: solid 1px #a8b7c0;
    cursor: default;
  }

  .mat-expansion-panel-header:not([aria-disabled="true"]) {
    cursor: default;
  }
}

app-bulk-import-main-page {
  .ng-select-container {
    width: 272px !important;
    height: 40px;
    padding: 12px 25px;
    border-radius: 5px !important;
    border: solid 1px #e7e7e7 !important;
    background-color: #f5f5f5 !important;
  }

  .ng-select .ng-arrow-wrapper .ng-arrow {
    color: var(--header-bg) !important;
  }

  .ng-dropdown-panel {
    padding: 17px 15px;
    border-top: none !important;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border: 2px solid var(--border);
  }
}

app-add-or-edit-country,
app-add-or-edit-cities {
  .mat-radio-container {
    .mat-radio-outer-circle {
      border: solid 1px var(--border);
    }
  }

  .radio-button-error
    .mat-radio-label
    .mat-radio-container
    .mat-radio-outer-circle {
    border: 1px solid var(--error) !important;
  }
}

app-add-edit-land-route {
  .ng-select {
    .ng-arrow-wrapper .ng-arrow {
      color: black !important;
    }

    .ng-select-container {
      border: 1px solid #bebebe !important;
      background-color: var(--companiesDropDownInNavBar) !important;
      height: 40px !important;
      padding-left: 12px !important;

      .ng-value-container .ng-placeholder {
        font-size: 12px;
      }
    }
  }
}

app-quote-routing-details-v2 {
  .error .radio-button-card,
  .error .circle {
    border: 1px solid var(--error) !important;
  }
}

app-routing-details-form-v2 {
  .mat-form-field {
    .mat-form-field-flex {
      padding: 0.3rem 0.5rem;
      height: 48px;
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      border-top: 0;
    }

    .mat-form-field-appearance-fill .mat-form-field-flex {
      padding: 0;
      border: 1px solid var(--primary);
      border-radius: 5px;
    }

    .mat-form-field-underline {
      display: none;
    }
  }
}

app-involved-parties-v2,
app-routing-details-form-v2 {
  .ng-dropdown-panel {
    margin-top: 1px !important;
  }

  .shipment-routing-field,
  .port-border {
    .ng-dropdown-panel .ng-dropdown-panel-items {
      border: 1px solid var(--primary) !important;
    }
  }
}

app-involved-parties-v2,
app-customs-clearance-form,
app-trucking-details-form,
app-routing-details-form-v2 {
  .ng-select-disabled .ng-select-container .ng-arrow-wrapper .ng-arrow {
    display: none;
  }

  .ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    font-size: 14px;
  }

  .radio-select {
    .ng-value {
      .circle {
        display: none;
      }
    }
  }

  .ng-option-selected {
    .circle {
      &:after {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--primary);
      }
    }
  }

  .value-circle {
    display: flex;
    width: 100%;

    .value-column {
      display: flex;
      flex-direction: column;

      .value {
        display: flex;
        flex-wrap: wrap;
        white-space: pre-wrap;

        .flag {
          margin-right: 6px;
          border-radius: 50%;
          width: 20px;
        }
      }

      .port {
        margin-left: 25px;
        margin-top: 6px;
        width: 230px;
        white-space: pre-wrap;
      }
    }
  }

  .ng-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-input {
    height: 100%;

    & > input {
      height: 100%;
    }
  }
}

app-involved-parties-v2 {
  .ng-dropdown-panel.ng-select-top,
  .ng-dropdown-panel.ng-select-bottom {
    display: flex;
    flex-direction: column-reverse;
  }

  .ng-dropdown-panel .ng-dropdown-footer {
    padding: 0.7rem 1.5rem 0.3rem 0.7rem !important;
    background-color: var(--field-bg);
    color: var(--primary);

    .add-shipper-consignee {
      margin-bottom: 0.4rem;
    }
  }
}

app-customs-clearance-form {
  .ng-dropdown-panel .ng-dropdown-panel-items {
    border: 1px solid var(--primary) !important;
    border-radius: 5px;

    .ng-option {
      padding: 18px;
      height: 50px;
    }
  }
}

app-root {
  app-white-nav-bar {
    grid-area: top;
    position: sticky;
    top: 0;
    z-index: 9999;
  }

  app-non-admin-sidebar,
  app-admin-sidebar {
    grid-area: sidebar;
    z-index: 9999;
  }
}

.options-container-overlay {
  position: absolute !important;
  z-index: 1000 !important;
}

.cdk-global-overlay-wrapper {
  align-items: normal !important;
  padding: 3%;

  .cdk-overlay-pane {
    max-height: fit-content;
  }

  &.options-list-overlay {
    padding: 0 !important;
  }
}

app-customs-clearance-route-search-with-details {
  ng-select.field {
    .ng-select-container {
      height: 39px !important;
      border-radius: 5px;
      border: solid 1px #e7e7e7 !important;
      background-color: #f5f5f5 !important;

      .ng-arrow-wrapper .ng-arrow {
        color: var(--header-bg) !important;
      }
    }

    .ng-option {
      .value-circle {
        display: flex;
        width: 100%;

        .value-column {
          display: flex;
          flex-direction: column;

          .value {
            display: flex;
            flex-wrap: wrap;
            white-space: pre-wrap;

            .flag {
              margin-right: 6px;
              border-radius: 50%;
              width: 20px;
            }
          }

          .port {
            margin-left: 25px;
            margin-top: 6px;
            width: 230px;
            white-space: pre-wrap;
          }
        }
      }
    }

    .ng-option-selected {
      .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        border-color: var(--primary) !important;

        &:after {
          content: "";
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: var(--primary);
        }
      }
    }
  }
}

// Add component name for non admin table view
app-product-table-view,
app-quote-table-view,
app-warehousing-list {
  .ngx-datatable {
    .datatable-footer .datatable-footer-inner {
      display: none;
    }

    .visible {
      overflow-x: scroll;
    }

    .datatable-header {
      border-radius: 5px;
      background-color: var(--companiesDropDownInNavBar);
      display: inline-table;

      .datatable-header-inner {
        height: 100%;

        .datatable-header-cell {
          padding-top: 7px;
          font-weight: 600;
          cursor: all-scroll;
          user-select: none;

          .sort-btn {
            display: none;
          }
        }
      }

      .datatable-header-cell.sortable .datatable-header-cell-wrapper {
        cursor: all-scroll;
      }
    }

    .datatable-body-cell,
    .datatable-header-cell {
      padding-left: 16px;
      padding-right: 16px;
      font-size: 13px;
    }

    .datatable-body-cell {
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;
    }

    .datatable-body-row .datatable-row-center {
      height: 53px;
      padding-top: 11px;
      border-radius: 6px;
      margin-top: 10px;
      border: 1px solid #a8b7c0;

      &:hover {
        background-color: var(--field-bg);
      }
    }

    .datatable-header-cell:first-child,
    .datatable-body-cell:first-child {
      max-width: 50px !important;
    }

    .datatable-header-cell:not(:first-child):not(:last-child):hover {
      border-right: 1px solid var(--border);
    }

    .datatable-body-row,
    .datatable-row-center,
    .datatable-body .datatable-scroll {
      min-width: 100%;
    }
  }
}

app-replace-linked-document {
  app-upload-document {
    .uploadCon {
      padding: 0 !important;

      .document-type-title {
        margin-top: 0px !important;
      }

      .buttons-container {
        width: 760px;
        z-index: 1000;
        position: relative;
      }
    }
  }

  .secondary-button-outline,
  .primary-button {
    width: 195px !important;
    height: 50px;
  }

  .secondary-button-outline {
    margin-left: auto;
  }

  .ddl-field {
    .ng-arrow-wrapper {
      margin-bottom: 16px;
    }

    .ng-select.ng-select-opened > .ng-select-container .ng-arrow-wrapper {
      margin-top: 16px;
      margin-bottom: 0px;
    }

    .hover-item {
      padding-left: 29px;
      font-size: 14px;
      font-weight: 100;
    }
  }
}

app-documents-bulk-product-list {
  app-checkbox .wrapper .dashmark:after {
    left: 3.6px !important;
    top: 0px !important;
  }
}

.add-employee-dialog {
  .mat-dialog-container {
    overflow-x: hidden !important;
  }
}

app-top-bar {
  ngb-popover-window {
    margin-top: 0.625rem !important;

    .arrow {
      left: 7.7rem !important;
    }
  }
}

app-shipment-details-creation-information-bar {
  ngb-popover-window {
    margin-top: 0.625rem !important;

    .arrow {
      left: 7.5rem !important;
    }
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  .dropdown-menu {
    box-shadow: 2px 1px 13px 1px #e9e9e9;
    border: none !important;
  }
}

.pause-dialog,
.undo-dialog,
.custom-filter-modal {
  mat-dialog-container {
    overflow: hidden !important;
  }
}

app-nav-sidebar,
app-non-admin-sidebar,
app-admin-sidebar {
  ngb-popover-window {
    padding: 10px !important;
    cursor: default;
    width: fit-content;
    position: fixed !important;

    .arrow {
      display: none !important;

      ::before {
        display: none !important;
      }

      ::after {
        display: none !important;
      }
    }

    .popover-body {
      padding: 0 !important;
      width: fit-content;
    }
  }
}

app-nav-sidebar {
  ngb-popover-window {
    margin-left: 0 !important;
  }
}

app-approval-details {
  .mat-tab-body-wrapper {
    margin-right: 15px !important;
  }
}

app-product-library-details {
  mat-tab-group mat-tab-body {
    margin-top: 0px !important;
  }
}

.warehousing-component-layout {
  .no-data-message {
    color: rgba(43, 43, 43, 0.6) !important;
  }
}

app-activity-log-filter-buttons {
  mat-checkbox {
    .mat-checkbox-layout {
      width: 14px !important;
      height: 14px !important;
      border-radius: 4px;
      background-color: var(--white);
      outline: 0;
      margin: 2px 4px 2px 0;

      .mat-checkbox-inner-container {
        margin-top: -1px !important;
        width: 14px !important;
        height: 14px !important;
        border-radius: 4px;
      }
    }
  }

  .all-btn {
    mat-checkbox {
      .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-frame {
        border-color: var(--header-bg) !important;
      }
    }

    .mat-checkbox-checked
      .mat-checkbox-layout
      .mat-checkbox-inner-container
      .mat-checkbox-background {
      background-color: var(--header-bg);
    }
  }

  .milestones-btn {
    mat-checkbox {
      .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-frame {
        border-color: var(--dark-sky-blue) !important;
      }
    }

    .mat-checkbox-checked
      .mat-checkbox-layout
      .mat-checkbox-inner-container
      .mat-checkbox-background {
      background-color: var(--dark-sky-blue);
    }
  }

  .activity-btn {
    mat-checkbox {
      .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-frame {
        border-color: var(--success) !important;
      }
    }

    .mat-checkbox-checked
      .mat-checkbox-layout
      .mat-checkbox-inner-container
      .mat-checkbox-background {
      background-color: var(--success);
    }
  }

  .delays-btn {
    mat-checkbox {
      .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-frame {
        border-color: #fe1414 !important;
      }
    }

    .mat-checkbox-checked
      .mat-checkbox-layout
      .mat-checkbox-inner-container
      .mat-checkbox-background {
      background-color: #fe1414;
    }
  }
}

app-add-edit-warehousing {
  .ng-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-input {
    top: unset;
  }

  .mat-form-field-suffix .mat-icon-button {
    align-items: unset;
  }

  .customDatepicker[_ngcontent-dqm-c575] .mat-form-field-flex {
    padding: unset !important;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: unset !important;
    padding-inline-start: 15px !important;
    align-items: center;
  }

  .mat-form-field-infix {
    display: flex !important;
  }

  .mat-icon {
    color: var(--primary);
  }

  .mat-form-field {
    display: block;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-wrapper {
    padding-bottom: unset;
  }

  .ng-invalid:not(form) {
    border-left: unset;
  }
}

app-product-registration-summary {
  .mat-checkbox-inner-container {
    width: 18px !important;
    height: 18px !important;
  }

  .mat-checkbox-frame {
    border: solid 1px #a8b7c0 !important;
  }
}

app-registration-information {
  .ng-select .ng-select-container {
    width: 343px;
    height: 48px;
  }
}

app-approval-details {
  .mat-tab-group {
    .mat-tab-label,
    .mat-tab-label-active {
      width: 20% !important;
      min-width: auto !important;
    }
  }
}

app-add-edit-trucking-route {
  app-trucking-route-details-section {
    .section .field {
      margin-right: 30px !important;
    }
  }
}

app-add-edit-trucking-pricing-details,
app-delay-charge-tiers {
  .combined-fields {
    .ng-select .ng-arrow-wrapper {
      display: none;
    }
  }
}

.add-network-member-dialog {
  .mat-dialog-container {
    padding: 30px 45px !important;
  }
}

app-add-edit-customer-services-bundle {
  .ng-select {
    &.ng-select-opened > .ng-select-container {
      box-shadow: 0 0 0 1px #384455;
    }

    &.check-boxes {
      .ng-option {
        pointer-events: none;
        color: gray !important;
        cursor: default !important;
      }
    }

    .ng-option-selected {
      &.ng-option-disabled {
        color: var(--subtext) !important;
      }

      .circle {
        background-color: var(--dark-sky-blue) !important;
        border: solid 4px #fff !important;
        outline-color: var(--dark-sky-blue);
        outline-style: solid;
      }
    }

    .ng-select-container {
      height: 40px !important;
      border-radius: 0 5px 5px 0;
      border: solid 1px #e7e7e7 !important;
      background-color: #f5f5f5 !important;
      font-family: AvertaPE;
      font-size: 16px !important;
      color: var(--subtext);
      border-radius: 5px;

      .ng-arrow-wrapper .ng-arrow {
        color: #2e3c4f !important;
      }

      .ng-value-container {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        flex: 1;
        align-items: center;
        font-family: AvertaPE;
        font-size: 16px !important;
        color: var(--subtext);
        align-items: baseline;
      }
    }

    .ng-dropdown-panel {
      box-shadow: none !important;
      z-index: 10;
      overflow: hidden;
      border: solid #000 1px;
      margin: 0;

      &.ng-select-bottom {
        border-radius: 0 0 15px 15px;
      }

      &.ng-select-top {
        border-radius: 15px 15px 0 0;
      }

      .ng-option {
        padding: 18px;

        .mat-radio-label {
          margin: 0 !important;
        }
      }
    }
  }

  .pricing-term {
    .ng-option {
      text-overflow: unset !important;
      overflow: hidden;
    }

    .ng-value-container {
      justify-content: center;
    }

    .ng-arrow-wrapper {
      display: none;
    }
  }

  .ng-invalid:not(form) {
    border-left: none;
    border-radius: 5px;
  }

  .ddl-error .ng-select-container {
    border: 1px solid var(--error) !important;
  }
}

app-edit-pricing-details {
  .ng-select {
    .ng-select-container {
      width: 59px;
      height: 38px;
      background-color: #f5f5f5 !important;
    }

    .ng-arrow-wrapper {
      display: none;
    }
  }
}

app-payment-terms {
  mat-tab-group mat-tab-body {
    height: 183px !important;
    overflow: hidden !important;
  }
}

app-add-edit-trucking-pricing-details {
  .ng-select .ng-select-container .ng-value-container {
    .ng-input > input {
      padding-top: 6px;
    }
  }
}

app-shipment-add-acl {
  .text-center {
    text-align: start !important;
  }

  .ng-dropdown-panel {
    position: relative;
  }

  .ng-dropdown-panel .scroll-host {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    overflow: scroll;
    position: relative;
    max-height: 200px;
  }

  .ng-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-input {
    top: unset;
  }

  .ng-value {
    width: 100%;
  }
}
app-add-or-edit-document-type {
  .auto-expand {
    min-height: 38px;
    resize: none;
    transition: height 0.2s ease-in-out;
    overflow-y: hidden;
  }
}

app-edit-operational-kpi,
app-add-or-edit-approval-kpis,
app-add-or-edit-product-registration-kpis,
app-add-or-edit-shipment-lead-time,
app-add-or-edit-document-type,
app-add-or-edit-industry {
  .ng-select .ng-arrow-wrapper .ng-arrow {
    color: var(--header-bg) !important;
  }
}

app-edit-operational-kpi,
app-add-or-edit-product-registration-kpis,
app-add-or-edit-approval-kpis,
app-add-or-edit-shipment-lead-time,
app-add-or-edit-document-type {
  .ddl-height .ng-select-container {
    height: 40px !important;
  }
}

app-edit-operational-kpi,
app-add-or-edit-approval-kpis,
app-add-or-edit-product-registration-kpis,
app-add-or-edit-shipment-lead-time {
  .left-combined-field .ng-select-container {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: none !important;
  }

  .ddl-error .ng-select-container {
    border: 1px solid var(--error) !important;
  }
}

app-users-management {
  .ngx-datatable {
    .datatable-body {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .datatable-footer {
      padding-top: 5px;
    }
  }
}

app-ddl-table {
  .ng-value-container {
    white-space: nowrap;
    padding-right: 5px;
  }

  app-checkbox {
    .wrapper .dashmark:checked:after {
      left: 3.2px !important;
      top: auto !important;
    }
  }
}

app-roles-management {
  .ngx-datatable {
    .datatable-body {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .datatable-footer {
      padding-top: 5px;
    }
  }
}

app-add-or-edit-tenant {
  .ng-select .ng-select-container {
    height: 40px !important;
  }
}

app-add-or-edit-tenant,
app-supplier-pricing-details {
  .ng-value-container .ng-input > input {
    padding-top: 5px;
  }
}

app-add-or-edit-cities {
  .ng-select .ng-select-container {
    height: 40px !important;

    .ng-value-container .ng-input > input {
      padding-top: 7px;
    }
  }
}
