.ngx-datatable.material {
	&.table-shadow {
		background: #FFF;
		box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
	}

	&.sm {
		font-size: 0.875rem;

		.btn-sm, .btn-group-sm > .btn {
			padding: 0 0.4rem;
			font-size: 0.875rem;
		}
	}

	&.table-striped {
		.datatable-row-even {
			background: #f9f9f9;
		}
	}


	&.single-selection,
	&.multi-selection,
	&.multi-click-selection {
		.datatable-body-row {
			&.active,
			&.active .datatable-row-group {
				background-color: #304FFE;
				color: #FFF;
			}

			&.active:hover,
			&.active:hover .datatable-row-group {
				background-color: #193AE4;
				color: #FFF;
			}

			&.active:focus,
			&.active:focus .datatable-row-group {
				background-color: #2041EF;
				color: #FFF;
			}
		}
	}

	&.table-hover:not(.cell-selection) {
		.datatable-body-row {
			&:hover,
			&:hover .datatable-row-group {
				background-color: #f5f5f5;
				/*transition-property: background;
				transition-duration: .3s;
				transition-timing-function: linear;*/
			}
		}
	}

	&:not(.cell-selection) {
		.datatable-body-row {
			&:focus,
			&:focus .datatable-row-group {
				background-color: #ddd;
			}
		}
	}


	&.cell-selection.table-hover {
		.datatable-body-cell {
			&:hover,
			&:hover .datatable-row-group {
				background-color: #f5f5f5;
				/*transition-property: background;
				transition-duration: .3s;
				transition-timing-function: linear;*/
			}

			&.active:hover,
			&.active:hover .datatable-row-group {
				background-color: #193AE4;
				color: #FFF;
			}
		}
	}

	&.cell-selection {
		.datatable-body-cell {
			&:focus,
			&:focus .datatable-row-group {
				background-color: #ddd;
			}

			&.active,
			&.active .datatable-row-group {
				background-color: #304FFE;
				color: #FFF;
			}

			&.active:focus,
			&.active:focus .datatable-row-group {
				background-color: #2041EF;
				color: #FFF;
			}
		}
	}
	/**
	 * Shared Styles
	 */
	.empty-row {
		height: 50px;
		text-align: left;
		padding: .5rem 1.2rem;
		vertical-align: top;
		border-top: 0;
	}

	.loading-row {
		text-align: left;
		padding: .5rem 1.2rem;
		vertical-align: top;
		border-top: 0;
	}
	/**
	 * Global Row Styles
	 */
	.datatable-header,
	.datatable-body {
		.datatable-row-left {
			background-color: #FFF;
			background-position: 100% 0;
			background-repeat: repeat-y;
			background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==);
		}

		.datatable-row-right {
			background-position: 0 0;
			background-color: #fff;
			background-repeat: repeat-y;
			background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
		}
	}
	/**
	 * Header Styles
	 */
	&.colored-header {
		.datatable-header {
			padding: .5rem 0;
			color: theme-color("light");
			background-color: theme-color("primary");
		}
	}

	&.table, &.table-bordered {
		.datatable-body {
			.datatable-body-row {
				border-top: 1px solid #ddd;
			}
		}
	}

	&.table-bordered {
		.datatable-header {
			.datatable-header-cell {
				padding: .9rem 1.2rem;
			}
		}
	}

	&.full-header {
		.datatable-header {
			.datatable-header-cell {
				padding: .9rem 1.2rem;
			}
		}
	}

	.datatable-header {
		border-bottom: 1px solid #ddd;

		.datatable-header-cell {
			text-align: left;
			padding: 0 1.2rem;
			vertical-align: bottom;
			font-weight: 500;

			.datatable-header-cell-wrapper {
				position: relative;
			}

			&.longpress {
				.draggable::after {
					transition: transform 400ms ease, opacity 400ms ease;
					opacity: .5;
					transform: scale(1);
				}
			}

			.draggable::after {
				content: " ";
				position: absolute;
				top: 50%;
				left: 50%;
				margin: -30px 0 0 -50px;
				height: 60px;
				width: 100px;
				background: #eee;
				opacity: 1;
				filter: none;
				transform: scale(0);
				z-index: 9999;
				pointer-events: none;
			}

			&.dragging {
				.resize-handle {
					border-right: none;
				}
			}
		}

		.resize-handle {
			border-right: solid 1px #eee;
		}
	}
	/**
	 * Body Styles
	 */
	.datatable-body {
		.datatable-row-detail {
			background: #f5f5f5;
			padding: 10px;
		}

		.datatable-group-header {
			background: #f5f5f5;
			border-bottom: solid 1px #D9D8D9;
			border-top: solid 1px #D9D8D9;
		}

		.datatable-body-row {
			.datatable-body-cell {
				text-align: left;
				padding: .5rem 1.2rem;
				vertical-align: top;
				border-top: 0;
				transition: width 0.3s ease;
				font-weight: 400;
				// cell active class
				// &.active {
				//  background: #0829e0
				// }
			}

			.datatable-body-group-cell {
				text-align: left;
				padding: .9rem 1.2rem;
				vertical-align: top;
				border-top: 0;
				color: rgba(0,0,0,.87);
				transition: width 0.3s ease;
				font-size: 0.875rem;
				font-weight: 400;
			}
		}

		.progress-linear {
			display: block;
			position: relative;
			width: 100%;
			height: 5px;
			padding: 0;
			margin: 0;
			position: absolute;

			.container {
				display: block;
				position: relative;
				overflow: hidden;
				width: 100%;
				height: 5px;
				-webkit-transform: translate(0,0) scale(1,1);
				transform: translate(0,0) scale(1,1);
				background-color: rgb(170,209,249);

				.bar {
					transition: all .2s linear;
					-webkit-animation: query .8s infinite cubic-bezier(.39,.575,.565,1);
					animation: query .8s infinite cubic-bezier(.39,.575,.565,1);
					transition: -webkit-transform .2s linear;
					transition: transform .2s linear;
					background-color: rgb(16,108,200);
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					width: 100%;
					height: 5px;
				}
			}
		}
	}
	/**
	 * Footer Styles
	 */
	.datatable-footer {
		border-top: 1px solid rgba(0, 0, 0, 0.12);
		font-size: 0.875rem;
		font-weight: 400;
		color: #777;

		.page-count {
			/*line-height: 50px;
			height: 50px;*/
			padding: 0 1.2rem;
		}

		.datatable-pager {
			margin: 0 10px;

			li {
				vertical-align: middle;

				&.disabled a {
					color: rgba(0, 0, 0, 0.26) !important;
					background-color: transparent !important;
				}

				&.active a {
					background-color: rgba(158,158,158,0.2);
					font-weight: bold;
				}
			}

			a {
				height: 22px;
				min-width: 24px;
				line-height: 22px;
				padding: 0 6px;
				border-radius: 3px;
				margin: 6px 3px;
				text-align: center;
				vertical-align: top;
				color: rgba(0,0,0,.54);
				text-decoration: none;
				vertical-align: bottom;

				&:hover {
					color: rgba(0,0,0,.75);
					background-color: rgba(158,158,158,0.2);
				}
			}

			.datatable-icon-left,
			.datatable-icon-skip,
			.datatable-icon-right,
			.datatable-icon-prev {
				font-size: 1.25rem;
				line-height: 1.25rem;
				padding: 0 3px;
			}
		}
	}
}

/**
 * Checkboxes
**/
.datatable-checkbox {
	position: relative;
	margin: 0;
	cursor: pointer;
	vertical-align: middle;
	display: inline-block;
	box-sizing: border-box;
	padding: 0;

	input[type='checkbox'] {
		position: relative;
		margin: 0 1rem 0 0;
		cursor: pointer;
		outline: none;

		&:before {
			-webkit-transition: all 0.3s ease-in-out;
			-moz-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
			content: "";
			position: absolute;
			left: 0;
			z-index: 1;
			width: 1rem;
			height: 1rem;
			border: 2px solid #f2f2f2;
		}

		&:checked:before {
			-webkit-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-ms-transform: rotate(-45deg);
			transform: rotate(-45deg);
			height: .5rem;
			border-color: #009688;
			border-top-style: none;
			border-right-style: none;
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 1rem;
			height: 1rem;
			background: #fff;
			cursor: pointer;
		}
	}
}

/**
 * Progress bar animations
 */
@keyframes query {
	0% {
		opacity: 1;
		transform: translateX(35%) scale(.3, 1);
	}

	100% {
		opacity: 0;
		transform: translateX(-50%) scale(0, 1);
	}
}
