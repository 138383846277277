.form-group {
  width: 100%;
  margin-bottom: 1.5rem !important;
  label {
    width: 100%;
    font-family: AvertaPE;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: var(--subtext);
  }
  .form-control {
    border-radius: 5px !important;
    border: solid 1px var(--border-storke) !important;
    background-color: var(--field-bg) !important;
    font-family: AvertaPE;
    font-size: 16px !important;
    height: 40px !important;
  }
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border: 1px solid var(--border-storke) !important;
}

.mat-radio-button {
  &.mat-radio-disabled .mat-radio-outer-circle {
    border-color: var(--border-storke) !important;
    background-color: rgba(42, 56, 75, 0.04) !important;
  }
  .mat-radio-outer-circle {
    border-color: #b3b3b3 !important;
    border-width: 1px !important;
    background-color: var(--white) !important;
  }
}
